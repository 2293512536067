import * as yup from 'yup'
import type { CartItem } from '@hitlabel.jp/main-site/types'

export const mustCheckSchema = yup
  .array()
  .test('check-required', 'いずれかにチェックを入れてください', (values: any) => {
    for (const v of values) {
      if (typeof v !== 'undefined' && v !== null && v !== '') {
        return true
      }
    }
    return false
  })

export const mustSelect = yup.string().test('must-select', '必ず選択してください', (value: any) => {
  if (typeof value === 'undefined' || value === null || value === '未選択' || value === '' || value === 'unselect') {
    return false
  }
  return true
})

export const validateSize = (currentItem: CartItem) => {
  if ((!currentItem || !currentItem.spec || !currentItem.spec.size) && currentItem.repeatId === 0) {
    return { status: false, message: '' }
  }
  const shortMin = currentItem.spec?.size?.shortMin as number
  const longMin = currentItem.spec?.size?.longMin as number
  const shortMax = currentItem.spec?.size?.shortMax as number
  const longMax = currentItem.spec?.size?.longMax as number

  const { sizeX, sizeY } = currentItem
  if (!Number.isInteger(sizeX) || !Number.isInteger(sizeY)) {
    return { status: false, message: '数値をご入力ください' }
  }
  if (sizeX < shortMin || sizeY < shortMin) {
    return { status: false, message: '最小サイズをご確認ください' }
  }
  if (sizeX > longMax || sizeY > longMax) {
    return { status: false, message: '最大サイズをご確認ください' }
  }
  if (sizeX < longMin && sizeY < longMin) {
    return { status: false, message: '最小サイズをご確認ください' }
  }
  if (sizeX > shortMax && sizeY > shortMax) {
    return { status: false, message: '最大サイズをご確認ください' }
  }

  return { status: true, message: '' }
}
