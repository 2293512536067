import { http} from './init'
import type { EstimateForm } from '@hitlabel.jp/main-site/types'
import { prefectureByNo } from '@hitlabel.jp/main-site/models/prefectures'

export const sendEstimateRequest = async (data: EstimateForm) => {
  data.deliveryTo = prefectureByNo(data.deliveryTo)

  const formData = new FormData()
  formData.append('json', JSON.stringify(data))
  if (data.sample1) {
    formData.append('sample1', data.sample1)
  }
  if (data.sample2) {
    formData.append('sample2', data.sample2)
  }
  return await http({
    method: 'post',
    url: '/v2/request-estimate',
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then((res) => {
      if (res.status === 200) {
        return true
      }
      return false
    })
    .catch((err) => {
      console.error(err)
      return false
    })
}
