export * from './epsilon'
export * from './effects'
export * from './methods'
import type { Checkout } from '@hitlabel.jp/main-site/types'
export const initialCheckout: Checkout = {
  paymentMethod: 'epsilon',
  totalAmount: 0,
  card: {
    token: '',
    maskedCardNo: '',
    installment: '10',
    errorMessage: '',
  },
  coupon: {
    discount: 0,
    code: '',
    validated: false,
    validating: false,
    errorMessage: '',
    message: '',
  },
  pointUse: 0,
  shipment: {
    source: 0,
    destination: 0,
    deliveryTime: 'none',
    codFee: 0,
  },
  transaction: false,
  hasEstimate: false,
  hasCannotOnDeliveryProduct: false,
  step: {
    current: 1,
    disable: [7],
    hides: [],
  },
  note: '',
  exampleUse: false,
  priorConsent: false,
  consent: {change: false, data: false, exampleUse: false, repeat: false},
  errorMessage: '',
}

export const mockCheckout: Checkout = {
  paymentMethod: 'epsilon',
  totalAmount: 0,
  card: {
    token: '',
    maskedCardNo: '',
    installment: '10',
    errorMessage: '',
  },
  coupon: {
    discount: 0,
    code: '',
    validated: false,
    validating: false,
    errorMessage: '',
    message: '',
  },
  pointUse: 0,
  shipment: {
    source: 0,
    destination: 0,
    deliveryTime: 'none',
    codFee: 0,
  },
  transaction: false,
  hasEstimate: false,
  hasCannotOnDeliveryProduct: false,
  step: {
    current: 0,
    disable: [7],
    hides: [],
  },
  note: '',
  exampleUse: false,
  priorConsent: false,
  consent: {change: false, data: false, exampleUse: false, repeat: false},
  errorMessage: '',
}
