import { HitlabelState, CalcResult, CartItem } from '@hitlabel.jp/main-site/types'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'

export const cartLoadFromLocalStorage = (payload: string, state: HitlabelState) => {
  let newCart = []
  try {
    newCart = JSON.parse(payload)
  } catch {
    newCart = []
  }
  return { ...state, cart: newCart }
}

const removeFromCartAllEvent = (totalAmount: number, items: CartItem[]) => {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'JPY',
      value: totalAmount,
      items: items.map(item => {
        return {
          item_id: item.product?.id,
          item_name: item.product?.name,
        }
      }),
    },
  })
}

export const cartClear = (state: HitlabelState) => {
  removeFromCartAllEvent(state.cart.totalAmount, state.cart.items)
  localStorage.removeItem(`${LS_PREFIX}:cart`)
  return { ...state, cart: { items: [], totalAmount: 0 } }
}

/**
 * idを元にカートアイテムを複製する
 **/
export const cartItemDuplicate = (payload: { id: number }, state: HitlabelState) => {
  const newState = { ...state }
  const targetItem = newState.cart.items.filter(item => {
    return item.id === payload.id
  })[0]
  const newItem = { ...targetItem }
  newItem.id = new Date().getTime()
  newState.cart.items.push(newItem)
  return newState
}

const removeFromCartEvent = (amount: number, productId: number, productName: string) => {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'JPY',
      value: amount,
      items: [
        {
          item_id: productId,
          item_name: productName,
        },
      ],
    },
  })
}

/**
 * idを元にカートアイテムを削除する
 **/
export const cartItemDelete = (payload: { id: number }, state: HitlabelState) => {
  const newState = { ...state }
  const deleted = newState.cart.items.filter(item => {
    if (item.id === payload.id) {
      removeFromCartEvent(
        item.calcResult?.detail.amountTotal as number,
        item.calcResult?.cartItem?.product?.id as number,
        item.calcResult?.cartItem?.product?.name as string,
      )
    }
    return item.id !== payload.id
  })
  if (deleted.length === 0) {
    localStorage.removeItem(`${LS_PREFIX}:cart`)
    return { ...state, cart: { items: [], totalAmount: 0 } }
  }
  newState.cart.items = deleted
  return newState
}

/**
 * idを元にカートアイテムの商品名を変更する
 **/
export const cartItemChangeName = (payload: { id: number; name: string; nameError: string }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].name = payload.name
      newState.cart.items[i].nameError = payload.nameError
    }
  }
  return newState
}

/**
 * idを元にカートアイテムの枚数を変更する
 **/
export const cartItemChangeNum = (payload: { id: number; num: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].num = payload.num
    }
  }
  return newState
}

/**
 * idを元にカートアイテムの納期を変更する
 **/
export const cartItemChangeShippingDate = (payload: { id: number; deliveryDate: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].deliveryDateProduct = payload.deliveryDate
    }
  }
  return newState
}

/**
 * idを元にカートアイテムの横サイズを変更する
 **/
export const cartItemChangeX = (payload: { id: number; x: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].sizeX = payload.x
    }
  }
  return newState
}

/**
 * idを元にカートアイテムの縦サイズを変更する
 **/
export const cartItemChangeY = (payload: { id: number; y: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].sizeY = payload.y
    }
  }
  return newState
}

/**
 * カートアイテムの入力サイズにエラーメッセージをセットする
 **/
export const cartItemSetErrorSize = (payload: { id: number; message: string }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].errors = { size: { message: payload.message } }
    }
  }
  return newState
}

/**
 * カートアイテムの入力サイズにエラーメッセージをクリアする
 **/
export const cartItemClearErrorSize = (payload: { id: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].errors = { size: { message: '' } }
    }
  }
  return newState
}

/**
 * カートの中身を再計算
 **/
export const cartItemCalcPrice = (payload: CalcResult, state: HitlabelState) => {
  const newState = { ...state }
  let totalPrice = 0
  for (const item of newState.cart.items) {
    if (payload === null || payload === undefined) {
      if (item.calcResult) {
        item.calcResult.detail.amountTotal = 0
      }
    } else {
      if (item.id !== 0 && item.id === payload.cartItem?.id) {
        const enabledNums = item.calcResult?.cartItem?.enabledNums.nums || []
        const enabledMaxNum = Math.max(...enabledNums)
        if (item.num > enabledMaxNum) {
          item.num = enabledMaxNum
        }
        item.calcResult = payload
      }
    }
    if (item.calcResult) {
      totalPrice = totalPrice + item.calcResult.detail.amountTotal
    }
  }
  newState.cart.totalAmount = totalPrice
  return newState
}

/**
 * カートの合計金額を計算
 **/
export const cartCalcTotalPrice = (state: HitlabelState) => {
  const newState = { ...state }
  let totalPrice = 0
  for (const item of newState.cart.items) {
    if (item.calcResult) {
      totalPrice = totalPrice + item.calcResult.detail.amountTotal
    }
  }
  newState.cart.totalAmount = totalPrice
  return newState
}

export const cartItemReceiveSize = (payload: { id: number; data: number }, state: HitlabelState) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].size = payload.data
    }
  }
  return newState
}

export const cartItemReceiveShape = (
  payload: { id: number; data: { id: number; name: string; sealSize: number } },
  state: HitlabelState,
) => {
  const newState = { ...state }
  for (let i = 0; i < newState.cart.items.length; i++) {
    if (newState.cart.items[i].id === payload.id) {
      newState.cart.items[i].size = payload.data.sealSize
      newState.cart.items[i].shape = payload.data.name
    }
  }
  return newState
}
