import * as yup from 'yup'
import { jisX0208Set } from '@hitlabel.jp/main-site/jisx0208'

export const validateJISX0208 = (value: string) => {
  if (!value) {
    return ''
  }

  let i = 0
  for (const char of value) {
    i++
    if (!jisX0208Set.has(char)) {
      // エラーを作成して返す
      return `使用できない文字が含まれています（${i}文字目）`
    }
  }
  return ''
}

export const jisx0208 = yup.string().test('jisx0208', '使用できない文字が含まれています', function(value) {
  if (!value) {
    return true
  }

  let i = 0
  for (const char of value) {
    i++
    if (!jisX0208Set.has(char)) {
      return this.createError({
        message: `使用できない文字が含まれています（${i}文字目）`,
        path: this.path,
      })
    }
  }
  return true
})

export const validateAddress = jisx0208
  .required('必ず入力してください')
  .test('matchPostalCode', '郵便番号と都道府県が一致しません', function(value) {
    const p = this.parent
    console.log(p)
    const input = `${p.addressLevel1}${p.addressLevel2}`
    if (!p.receivedPostalData || p.receivedPostalData.length === 0) {
      return this.createError({
        message: '郵便番号をご確認ください',
        path: this.path,
      })
    }
    for (const v of p.receivedPostalData) {
      const received = `${v.addressLevel1}${v.addressLevel2}`
      if (input.startsWith(received)) {
        return true
      }
    }
    return false
  })
