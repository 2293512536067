import React from 'react'
import { ActionType } from '@hitlabel.jp/main-site/hooks/hitlabel/actions'
import { Action } from '@hitlabel.jp/main-site/types'

export const methods = (dispatch: React.Dispatch<Action>) => {
  const checkoutChangeCoupon = (value: string) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_COUPON, payload: value })
  }

  const checkoutChangePoint = (value: number) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_POINT, payload: value })
  }

  const checkoutChangePaymentMethod = (value: string) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_PAYMENT_METHOD, payload: value })
  }

  const checkoutChangeShipmentDestination = (value: number) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_SHIPMENT_DESTINATION, payload: value })
  }

  const checkoutChangeShipmentSource = (value: number) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_SHIPMENT_SOURCE, payload: value })
  }

  const checkoutChangeShipmentDeliveryTime = (value: string) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_SHIPMENT_DELIVERY_TIME, payload: value })
  }

  const checkoutChangeNote = (value: string) => {
    dispatch({ type: ActionType.CHECKOUT_CHANGE_NOTE, payload: value })
  }
  const consentChangeExampleUse = (checked: boolean) => {
    dispatch({ type: ActionType.CONSENT_CHANGE_EXAMPLE_USE, payload: checked })
  }
  const priorConsentChange = (checked: boolean) => {
    dispatch({ type: ActionType.PRIOR_CONSENT_CHANGE, payload: checked })
  }

  return {
    checkoutChangeCoupon,
    checkoutChangePoint,
    checkoutChangePaymentMethod,
    checkoutChangeShipmentDestination,
    checkoutChangeShipmentSource,
    checkoutChangeShipmentDeliveryTime,
    checkoutChangeNote,
    consentChangeExampleUse,
    priorConsentChange,
  }
}
