import type { HitlabelState, Coupon, DeliveryTime, PurchaseResponse, PaymentMethod } from '@hitlabel.jp/main-site/types'
import { navigate } from 'gatsby'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'
import { getCommission } from '@hitlabel.jp/main-site/utils'

export const checkoutStart = (state: HitlabelState) => {
  const newState = { ...state }
  newState.checkout.totalAmount = newState.cart.totalAmount
  newState.checkout.shipment.codFee = getCommission(newState.cart.totalAmount)
  return newState
}

export const checkoutChangePaymentMethod = (payload: PaymentMethod, state: HitlabelState) => {
  const checkoutState = state.checkout
  // 全額ポイント払いを選んだ場合に保有ポイントが合計金額より少ない場合は反応しない
  if (payload === 'point') {
    if (state.user.holdingPoint < state.cart.totalAmount - checkoutState.coupon.discount) {
      return { ...state }
    }
      checkoutState.pointUse = state.cart.totalAmount - checkoutState.coupon.discount
      checkoutState.totalAmount = 0
  } else {
    if (payload === 'onDelivery') {
      // 代引きのときに手数料を合計金額に足す
      checkoutState.totalAmount = checkoutState.totalAmount + checkoutState.shipment.codFee
    } else {
      checkoutState.totalAmount = state.cart.totalAmount
    }
    if (checkoutState.pointUse >= state.cart.totalAmount - checkoutState.coupon.discount) {
      return { ...state }
    }
  }
  const newCheckoutState = { ...checkoutState, paymentMethod: payload }
  return { ...state, checkout: newCheckoutState }
}

export const checkoutRequestSucceed = (payload: PurchaseResponse, state: HitlabelState) => {
  // イプシロン決済で3Dセキュア認証が必要な場合
  if (payload.purchase.method === 'epsilon' && payload.pareq && payload.pareq !== '') {
    const newState = { ...state }
    newState.checkout.card = {
      ...state.checkout.card,
      pareq: payload.pareq,
      redirect: payload.redirect,
      md: payload.purchase.purchaseId.toString()
    }
    newState.requesting = false
    navigate('/checkout/tds')
    return newState
  }
  const newState = { ...state }
  newState.requesting = false
  newState.cart = { items: [], totalAmount: 0 }
  localStorage.removeItem(`${LS_PREFIX}:cart`)
  newState.checkout.consent = { change: false, data: false, exampleUse: false, repeat: false }
  if (payload.redirect && payload.redirect !== '' && window) {
    window.location.href = payload.redirect
  } else {
    navigate(`/checkout/done/${payload.purchase.purchaseId}`)
  }
  return newState
}

export const checkoutRequestFail = (payload: string, state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  newState.checkout.errorMessage = payload
  navigate('/checkout/error')
  return newState
}

export const checkoutChangeCoupon = (payload: string, state: HitlabelState) => {
  const checkoutState = state.checkout
  checkoutState.coupon.code = payload
  const newCheckoutState = { ...checkoutState }
  return { ...state, checkout: newCheckoutState }
}

export const checkoutValidateCoupon = (state: HitlabelState) => {
  const checkoutState = state.checkout
  checkoutState.coupon = {
    ...checkoutState.coupon,
    discount: 0,
    validated: false,
    errorMessage: '',
    message: '',
    validating: true,
  }
  const newCheckoutState = { ...checkoutState }
  return { ...state, checkout: newCheckoutState }
}
export const checkoutValidateCouponDone = (payload: Coupon, state: HitlabelState) => {
  const newState = { ...state }
  const newCheckoutState = newState.checkout
  newCheckoutState.coupon = {
    code: payload.code,
    discount: payload.discount,
    validated: true,
    errorMessage: '',
    message: '',
    validating: false,
  }
  newCheckoutState.totalAmount =
    newState.cart.totalAmount - newCheckoutState.coupon.discount - newCheckoutState.pointUse
  return { ...newState, checkout: newCheckoutState }
}

export const checkoutValidateCouponFail = (state: HitlabelState) => {
  const newState = { ...state }
  const newCheckoutState = newState.checkout
  newCheckoutState.coupon = {
    ...newCheckoutState.coupon,
    discount: 0,
    validated: false,
    errorMessage: '無効なクーポンです',
    validating: false,
  }
  newCheckoutState.totalAmount =
    newState.cart.totalAmount - newCheckoutState.coupon.discount - newCheckoutState.pointUse
  return { ...newState, checkout: newCheckoutState }
}

export const checkoutChangePoint = (payload: number, state: HitlabelState) => {
  const newState = { ...state }
  const newCheckoutState = newState.checkout
  let p = payload
  if (p < 0) {
    p = 0
  }
  if (p > state.user.holdingPoint) {
    p = state.user.holdingPoint
  }
  if (p > state.cart.totalAmount - state.checkout.coupon.discount) {
    p = state.cart.totalAmount - state.checkout.coupon.discount
  }
  newCheckoutState.pointUse = p
  newCheckoutState.totalAmount =
    newState.cart.totalAmount - newCheckoutState.coupon.discount - newCheckoutState.pointUse
  return { ...newState, checkout: newCheckoutState }
}

export const checkoutChangeShipmentSource = (payload: number, state: HitlabelState) => {
  const checkoutState = state.checkout
  const shipmentState = checkoutState.shipment
  shipmentState.source = payload
  const newCheckoutState = { ...checkoutState, shipment: shipmentState }
  return { ...state, checkout: newCheckoutState }
}

export const checkoutChangeShipmentDestination = (payload: number, state: HitlabelState) => {
  const checkoutState = state.checkout
  const shipmentState = checkoutState.shipment
  shipmentState.destination = payload
  const newCheckoutState = { ...checkoutState, shipment: shipmentState }
  return { ...state, checkout: newCheckoutState }
}

export const checkoutChangeShipmentDeliveryTime = (payload: DeliveryTime, state: HitlabelState) => {
  const checkoutState = state.checkout
  const shipmentState = checkoutState.shipment
  shipmentState.deliveryTime = payload
  const newCheckoutState = { ...checkoutState, shipment: shipmentState }
  return { ...state, checkout: newCheckoutState }
}

export const checkoutChangeNote = (payload: string, state: HitlabelState) => {
  const newState = { ...state }
  newState.checkout.note = payload
  return newState
}

export const consentChangeExampleUse = (payload: boolean, state: HitlabelState) => {
  const newState = { ...state }
  newState.checkout.exampleUse = payload
  return newState
}

export const priorConsentChange = (payload: boolean, state: HitlabelState) => {
  const newState = { ...state }
  newState.checkout.priorConsent = payload
  return newState
}
