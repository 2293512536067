import React, { useCallback } from 'react'
import { ActionType } from '../actions'
import { Action, User } from '@hitlabel.jp/main-site/types'
import { userSignup, resetUrlRequest, resetRequest, validateResetTokenRequest } from '@hitlabel.jp/main-site/services'

export const methods = (dispatch: React.Dispatch<Action>) => {
  const signupConfirm = useCallback((data: User) => {
    dispatch({ type: ActionType.AUTH_SIGNUP_CONFIRM, payload: data })
  }, [])

  const signup = useCallback((data: User) => {
    dispatch({ type: ActionType.AUTH_SIGNUP_REQUEST })
    userSignup(data).then(res => {
      dispatch({ type: ActionType.AUTH_SIGNUP_RECEIVE, payload: res })
      dispatch({ type: ActionType.GA4_SIGN_UP, payload: data })
    })
  }, [])

  const resetUrl = useCallback((email: string) => {
    dispatch({ type: ActionType.AUTH_RESET_URL_REQUEST })
    resetUrlRequest(email).then((res: any) => {
      if (res.data.message === '') {
        dispatch({ type: ActionType.AUTH_RESET_URL_REQUEST_SUCCEED })
      } else {
        dispatch({ type: ActionType.AUTH_RESET_URL_REQUEST_FAIL, payload: res.data.message })
      }
    })
  }, [])

  const validateResetToken = useCallback((token: string) => {
    dispatch({ type: ActionType.AUTH_RESET_TOKEN_VALIDATE })
    validateResetTokenRequest(token).then(res => {
      if (res) {
        dispatch({ type: ActionType.AUTH_RESET_TOKEN_VALIDATE_REQUEST_SUCCEED })
      } else {
        dispatch({ type: ActionType.AUTH_RESET_TOKEN_VALIDATE_REQUEST_FAIL })
      }
    })
  }, [])

  const reset = useCallback((token: string, password: string) => {
    dispatch({ type: ActionType.AUTH_RESET_REQUEST })
    resetRequest(token, password).then(res => {
      if (res) {
        dispatch({ type: ActionType.AUTH_RESET_REQUEST_SUCCEED })
      }
    })
  }, [])

  return {
    signupConfirm,
    signup,
    resetUrl,
    validateResetToken,
    reset,
  }
}
