import type {
  HitlabelState,
  CartItem,
  CalcDetail,
  CalcResult,
  OrderHistory,
  ContactForm,
} from '@hitlabel.jp/main-site/types'
import { mockCheckout as initialCheckout } from './checkout'
export const initialContactForm = (): ContactForm => {
  return {
    orderStatus: 'ご注文前',
    orderId: null,
    organization: '',
    familyName: '',
    givenName: '',
    tel: '',
    email: '',
    message: '',
  }
}
export const initialCurrentItem = (): CartItem => {
  return {
    id: 0,
    type: '',
    purchaseId: 0,
    orderId: 0,
    status: 0,
    name: '',
    nameError: '',
    sizeX: 0,
    sizeY: 0,
    size: 0,
    num: 0,
    deliveryDateProduct: 5,
    deliveryDateOption: 0,
    amountProduct: 0,
    amountOption: 0,
    amountTotal: 0,
    repeatId: 0,
    shippingDate: 0,
    form: {
      size: { active: false, touched: false, error: '', additionalDays: 0 },
      adhesive: { active: false, touched: false, error: '', additionalDays: 0 },
      ondemandFoil: { active: false, touched: false, error: '', additionalDays: 0 },
      whiteInk: { active: false, touched: false, error: '', additionalDays: 0 },
      whitedataService: { active: false, touched: false, error: '', additionalDays: 0 },
      lamination: { active: false, touched: false, error: '', additionalDays: 0 },
      format: { active: false, touched: false, error: '', additionalDays: 0 },
      halfcut: { active: false, touched: false, error: '', additionalDays: 0 },
      cutpath: { active: false, touched: false, error: '', additionalDays: 0 },
      cutlineService: { active: false, touched: false, error: '', additionalDays: 0 },
      slit: { active: false, touched: false, error: '', additionalDays: 0 },
      backSide: { active: false, touched: false, error: '', additionalDays: 0 },
      deliveryForm: { active: false, touched: false, error: '', additionalDays: 0 },
      sendCopy: { active: false, touched: false, error: '', additionalDays: 0 },
      deliveryDate: { active: false, touched: false, error: '', additionalDays: 0 },
      num: { active: false, touched: false, error: '', additionalDays: 0 },
      shape: { active: false, touched: false, error: '', additionalDays: 0 },
      individualPackaging: { active: false, touched: false, error: '', additionalDays: 0 },
    },
    selectProgress: {
      num: 0,
      touched: 0,
    },
    enabledNums: {
      id: 0,
      nums: [0],
      sizeName: '',
    },
    enabledDeliveryDate: [7, 5, 3],
    estimate: {
      id: '',
      createdAt: 0,
      updatedAt: 0,
      deletedAt: 0,
      email: '',
      estimateNumber: 0,
      estimateFrom: 'unknown',
      status: '',
      title: '',
      request: '',
      response: '{"children": []}',
    },
    freeFormatOptionTitle: '',
    freeFormatOptionBody: '',
    freeFormatOptionAmount: 0,
    freeFormatOptionRepeat: false,
  }
}

export const initialUser = () => {
  return {
    id: 0,
    email: '',
    organization: '',
    familyName: '',
    givenName: '',
    familyKana: '',
    givenKana: '',
    postalCode: '',
    addressLevel1: '',
    addressLevel2: '',
    addressLine1: '',
    addressLine2: '',
    tel: '',
    fax: '',
    emergencyTel: '',
    holdingPoint: 0,
    sources: [],
    destinations: [],
    activeOrders: [],
    deliveredOrders: [],
    activeEstimates: [],
    estimateTo: '',
  }
}

export const initialState = (): HitlabelState => {
  return {
    location: null,
    userMenuOpen: false,
    menuOpen: false,
    user: initialUser(),
    auth: {
      token: '',
      isAuthed: null,
      backTo: '',
      resetTokenValidated: null,
    },
    currentItem: initialCurrentItem(),
    cart: {
      items: [],
      totalAmount: 0,
    },
    checkout: initialCheckout,
    sampleForm: {
      sample: [],
      organization: '',
      name: '',
      kana: '',
      postalCode: '',
      addressLevel1: '',
      addressLevel2: '',
      addressLine1: '',
      addressLine2: '',
      tel: '',
      email: '',
    },
    estimateForm: {
      purpose: '',
      adhesive: 'unselect',
      placement: [],
      placementEtc: '',
      material: 'unselect',
      sizeX: '0',
      sizeY: '0',
      shape: '',
      whiteInk: 'なし',
      color: 'unselect',
      tokusyoku: [],
      tokusyokuText: '',
      lamination: '',
      specialProcessing: 'なし',
      num0: '0',
      num1: '0',
      num2: '0',
      deliveryForm: 'シートカット',
      rollRoll: '未指定',
      rollPlacement: '未指定',
      rollSize: '未指定',
      packaging: '希望しない',
      submission: 'イラストレーター・フォトショップ',
      deliveryTo: 'お客様情報と同じ',
      isHurry: '未定',
      sample1: null,
      sample2: null,
      etc: '',
      organization: '',
      name: '',
      kana: '',
      postalCode: '',
      addressLevel1: '',
      addressLevel2: '',
      addressLine1: '',
      addressLine2: '',
      tel: '',
      email: '',
    },
    contactForm: initialContactForm(),
    signupForm: initialUser(),
    requesting: false,
    requestError: '',
    addressFormDefaultValues: {
      id: 0,
      postalCode: '',
      addressLevel1: '',
      addressLevel2: '',
      addressLine1: '',
      addressLine2: '',
      organization: '',
      familyName: '',
      givenName: '',
      familyKana: '',
      givenKana: '',
      tel: '',
    },
  }
}

export const initialOrderHistory: OrderHistory = {
  id: 0,
  createdAt: 0,
  updatedAt: 0,
  deletedAt: 0,
  purchaseId: 0,
  orderId: 0,
  productId: 0,
  shapeId: 0,
  name: '',
  num: 0,
  size: 0,
  status: 0,
  lamination: 0,
  adhesive: 0,
  halfcut: 0,
  cutpath: 0,
  slit: 0,
  deliveryForm: 0,
  deliveryDateProduct: 0,
  deliveryDateOption: 0,
  amountProduct: 0,
  amountOption: 0,
  point: 0,
  repeatId: 0,
  whiteInk: 0,
  sendCopy: 0,
  shippingDate: 0,
  cutlineService: 0,
  whitedataService: 0,
  sizeX: 0,
  sizeY: 0,
  canExampleUse: 0,
  deliveryProvider: '',
  trackingNumber: '',
  shipmentMemo: '',
  estimateData: '',
  backSide: 0,
  freeFormatOptionTitle: '',
  freeFormatOptionBody: '',
  freeFormatOptionAmount: 0,
  freeFormatOptionRepeat: false,
  individualPackaging: 0,
  ondemandFoil: 0,
}

export const initialCalcDetail: CalcDetail = {
  adhesive: 0,
  amountOption: 0,
  amountProduct: 0,
  amountTotal: 0,
  backSide: 0,
  cutlineService: 0,
  cutpath: 0,
  deliveryForm: 0,
  halfcut: 0,
  lamination: 0,
  product: 0,
  sendCopy: 0,
  sizeName: '',
  slit: 0,
  whiteInk: 0,
  whitedataService: 0,
  point: 0,
  individualPackaging: 0,
  ondemandFoil: 0,
}

export const initialCalcResult: CalcResult = {
  order: initialOrderHistory,
  detail: initialCalcDetail,
}
