import React from 'react'
import { HitlabelState, Action, CalcResult } from '@hitlabel.jp/main-site/types'
import { ActionType } from '@hitlabel.jp/main-site/hooks/hitlabel/actions'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'
import { getSize, getShape, calcprice } from '@hitlabel.jp/main-site/services'
import { validateSize } from '@hitlabel.jp/main-site/validate'
import toast from 'react-hot-toast'

export const effects = (state: HitlabelState, dispatch: React.Dispatch<Action>) => {
  /**
   * カートが開かれたときに合計金額の計算などをおこなう
   **/
  React.useEffect(() => {
    if (state.location?.pathname === '/cart' && state.cart.items.length > 0) {
      for (const item of state.cart.items) {
        if (item.type === 'estimate') {
          calcprice(item).then(res => {
            dispatch({ type: ActionType.CART_ITEM_CALC_PRICE, payload: res.data })
          })
          localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(state.cart))
        } else {
          const validationResult = validateSize(item)
          if (validationResult.status === false) {
            dispatch({
              type: ActionType.CART_ITEM_SET_ERROR_SIZE,
              payload: { id: item.id, message: validationResult.message },
            })
          } else {
            dispatch({
              type: ActionType.CART_ITEM_CLEAR_ERROR_SIZE,
              payload: { id: item.id },
            })

            // サイズや枚数が未入力の場合には計算しないようにする
            // ただしリピート注文のときは過去にsizeXとsizeYが入っていないデータが存在したので、sizeXとsizeYのチェックはスルー
            if (!item.num || !(item.num > 0)) {
              // do nothing
            } else {
              if (item.repeatId !== 0) {
                calcprice(item).then(res => {
                  dispatch({ type: ActionType.CART_ITEM_CALC_PRICE, payload: res.data })
                  if (res.data === null) {
                    toast('ご指定の条件ではご注文いただけません', { icon: '⚠' })
                  }
                })
              } else {
                if (!item.sizeX || !(item.sizeX > 0) || !item.sizeY || !(item.sizeY > 0)) {
                  // do nothing
                } else {
                  calcprice(item).then(res => {
                    dispatch({ type: ActionType.CART_ITEM_CALC_PRICE, payload: res.data })
                    if (res.data === null) {
                      toast('ご指定の条件ではご注文いただけません', { icon: '⚠' })
                    }
                  })
                }
              }
            }
            localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(state.cart))
          }
        }
      }
    }
  }, [state.location?.pathname, state.cart.items, state.user.email])
  /**
   * カート内アイテムのサイズが変更されたときに、サイズチェックや面積、形状の取得を行う
   **/
  const sizes = state.cart.items.map(item => {
    return { x: item.sizeX, y: item.sizeY }
  })
  React.useEffect(() => {
    for (const item of state.cart.items) {
      if (item.type === 'estimate') {
      } else {
        const validationResult = validateSize(item)
        if (validationResult.status === false) {
          dispatch({
            type: ActionType.CART_ITEM_SET_ERROR_SIZE,
            payload: { id: item.id, message: validationResult.message },
          })
        } else {
          dispatch({
            type: ActionType.CART_ITEM_CLEAR_ERROR_SIZE,
            payload: { id: item.id },
          })
          if (item.productType === 'general') {
            getSize(item)
              .then(res => {
                dispatch({ type: ActionType.CART_ITEM_RECEIVE_SIZE, payload: { id: item.id, data: res } })
              })
              .catch(err => {
                console.error(err)
              })
          }
          if (item.productType === 'bigsticker') {
            getShape(item)
              .then(res => {
                dispatch({ type: ActionType.CART_ITEM_RECEIVE_SHAPE, payload: { id: item.id, data: res } })
              })
              .catch(err => {
                console.error(err)
              })
          }
          localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(state.cart))
        }
      }
    }
  }, [JSON.stringify(sizes)])
  /**
   * カート内が変更されたら入力内容をバリデーション＆価格の再計算を行う
   **/
  React.useEffect(() => {
    for (const item of state.cart.items) {
      if (item.type === 'estimate') {
        calcprice(item).then(res => {
          dispatch({ type: ActionType.CART_ITEM_CALC_PRICE, payload: res.data })
        })
        localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(state.cart))
      } else {
        const validationResult = validateSize(item)
        if (validationResult.status === false) {
          dispatch({
            type: ActionType.CART_ITEM_SET_ERROR_SIZE,
            payload: { id: item.id, message: validationResult.message },
          })
        } else {
          dispatch({
            type: ActionType.CART_ITEM_CLEAR_ERROR_SIZE,
            payload: { id: item.id },
          })

          // サイズや枚数が未入力の場合には計算しないようにする
          if (!item.num || !(item.num > 0) || !item.sizeX || !(item.sizeX > 0) || !item.sizeY || !(item.sizeY > 0)) {
          } else {
            calcprice(item).then(res => {
              dispatch({ type: ActionType.CART_ITEM_CALC_PRICE, payload: res.data })
              if (res.data === null) {
                toast('ご指定の条件ではご注文いただけません', { icon: '⚠' })
              }
            })
          }
          localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(state.cart))
        }
      }
    }
  }, [JSON.stringify(state.cart.items)])

  /**
   * GA4 view_itemイベント
   */
  React.useEffect(() => {
    if (state.location?.pathname === '/cart') {
      dispatch({ type: ActionType.GA4_VIEW_CART })
    }
  }, [state.location?.pathname])
}
