import { HitlabelState, User } from '@hitlabel.jp/main-site/types'
import { initialUser } from '@hitlabel.jp/main-site/hooks/hitlabel/initial-state'
import { navigate } from 'gatsby'
import toast from 'react-hot-toast'

export const authSignupConfirm = (payload: User, state: HitlabelState) => {
  const newState = { ...state }
  newState.signupForm = payload
  return newState
}

export const authSignupRequest = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const authSignupReceive = (payload: any, state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  if (payload === true) {
    newState.signupForm = initialUser()
    toast.success('会員登録が完了しました')
    navigate('/')
    return newState
  }
  toast.error(payload.message)
  return newState
}

export const authResetUrlRequest = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  newState.requestError = ''
  return newState
}

export const authResetUrlRequestFail = (payload: string, state: HitlabelState) => {
  const newState = { ...state }
  newState.requestError = payload
  newState.requesting = false
  toast.error(payload)
  return newState
}

export const authResetUrlRequestSucceed = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requestError = ''
  newState.requesting = false
  toast.success('パスワードリセットのリクエストに成功しました')
  navigate('/reset/sent')
  return newState
}

export const authResetTokenValidate = (state: HitlabelState) => {
  const newState = { ...state }
  newState.auth.resetTokenValidated = null
  return newState
}

export const authResetTokenValidateRequestSucceed = (state: HitlabelState) => {
  const newState = { ...state }
  newState.auth.resetTokenValidated = true
  return newState
}
export const authResetTokenValidateRequestFail = (state: HitlabelState) => {
  const newState = { ...state }
  newState.auth.resetTokenValidated = false
  return newState
}

export const authResetRequest = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const authResetRequestSucceed = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  toast.success('パスワードの変更が完了しました')
  navigate('/login')
  return newState
}
