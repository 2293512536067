import React from 'react'
import type { HitlabelState, Action } from '@hitlabel.jp/main-site/types'
import { useDebounce } from '@hitlabel.jp/main-site/hooks'
import { ActionType } from '../actions'
import { validateCoupon } from '@hitlabel.jp/main-site/services'

export const effects = (state: HitlabelState, dispatch: React.Dispatch<Action>) => {
  const { debouncedValue } = useDebounce(state.checkout.coupon.code)
  React.useEffect(() => {
    const couponCode = debouncedValue as string
    if (couponCode && couponCode.length > 2) {
      dispatch({ type: ActionType.CHECKOUT_VALIDATE_COUPON })
      validateCoupon(couponCode, state.cart.totalAmount)
        .then(res => {
          if (res) {
            dispatch({ type: ActionType.CHECKOUT_VALIDATE_COUPON_DONE, payload: res })
          }
        })
        .catch(err => {
          dispatch({ type: ActionType.CHECKOUT_VALIDATE_COUPON_FAIL })
          dispatch({ type: ActionType.CART_CALC_TOTAL_PRICE })
        })
    }
  }, [debouncedValue])

  React.useEffect(() => {
    if (state.location) {
      if (state.location.pathname.includes('/checkout/payment')) {
        dispatch({ type: ActionType.CHECKOUT_START })
        dispatch({ type: ActionType.GA4_BEGIN_CHECKOUT })
      }
    }
  }, [state.location?.pathname])
}
