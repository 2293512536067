import { http } from './init'
import { CartItem } from '@hitlabel.jp/main-site/types'
import camelcaseKeys from 'camelcase-keys'

export const getSize = async (values: CartItem) => {
  if (!values.product) {
    return
  }
  return await http({
    method: 'post',
    url: '/v2/size',
    data: JSON.stringify(values),
  })
    .then(res => {
      if (res.status === 200) {
        return Number(res.data)
      }
      return null
    })
    .catch(err => {
      console.error(err)
      return false
    })
}

export const getShape = async (values: CartItem) => {
  if (!values.product) {
    return
  }
  return await http({
    method: 'post',
    url: '/v2/shape',
    data: JSON.stringify(values),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      return camelcaseKeys(res.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
      return {}
    })
}
