import { CancelTokenSource } from 'axios'
import { http } from '../init'
import camelcaseKeys from 'camelcase-keys'
import { Purchase } from '@hitlabel.jp/main-site/types'

export const uploadOrderData = (
  file: File,
  orderId: number,
  subscribeProgress: (e: any) => void,
  cancel: CancelTokenSource,
) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('order_id', String(orderId))
  return http({
    url: '/v2/upload',
    method: 'post',
    data: formData,
    cancelToken: cancel.token,
    onUploadProgress: subscribeProgress,
  })
}

export const requestPurchase = async (purchaseId: number) => {
  return await http({
    url: `/v2/purchases/${purchaseId}`,
    method: 'get',
  }).then(res => {
    return camelcaseKeys(res.data.data, { deep: true }) as Purchase
  })
}

export const requestPurchases = async () => {
  return await http({
    url: `/v2/purchases`,
    method: 'get',
  }).then(res => {
    return camelcaseKeys(res.data.data, { deep: true })
  })
}

export const requestThanks = async (purchaseId: number) => {
  return await http({
    url: `/v2/thanks/${purchaseId}`,
    method: 'get',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(() => {
      return false
    })
}

export const purchaseCancel = async (id: number) => {
  return await http({
    url: `/v2/cancel/${id}`,
    method: 'put',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const issueInvoice = async (to: string, id: number) => {
  return await http({
    url: `/v2/issue-invoice`,
    method: 'post',
    data: {
      to: to,
      purchase_id: id,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const issueReceipt = async (to: string, id: number) => {
  return await http({
    url: `/v2/issue-receipt`,
    method: 'post',
    data: {
      to: to,
      purchase_id: id,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const activeOrders = async (purchaseId: number) => {
  return await http({
    url: `/v2/purchases/${purchaseId}`,
    method: 'get',
  }).then(res => {
    return camelcaseKeys(res.data.data, { deep: true })
  })
}

export const requestActivePurchases = async () => {
  return await http({
    url: `/v2/active-orders`,
    method: 'get',
  })
    .then(res => {
      return camelcaseKeys(res.data.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
    })
}

export const requestDeliveredPurchases = async () => {
  return await http({
    url: `/v2/delivered-orders`,
    method: 'get',
  })
    .then(res => {
      return camelcaseKeys(res.data.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
    })
}

export const changeShipment = async (purchaseId: number, destId: number, srcId: number, deliveryTime: string) => {
  return await http({
    url: `/v2/shipment/${purchaseId}`,
    method: 'put',
    data: {
      purchaseId: purchaseId,
      destination: String(destId),
      source: String(srcId),
      deliveryTime: deliveryTime,
    },
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export const downloadSampleImage = async (id: number) => {
  return await http({
    url: `/v2/sample-image/${id}`,
    method: 'get',
  })
    .then(res => {
      return res.data.data
    })
    .catch(err => {
      console.error(err)
    })
}
