import React from 'react'
import type { CreditCard } from '@hitlabel.jp/main-site/types'

interface Window {
  EpsilonToken: any
  execTrade: (response: any) => any
}

declare let window: Window

export const useEpsilon = () => {
  const [token, setToken] = React.useState('')
  const [maskedCardNo, setMaskedCardNo] = React.useState('')
  const [errorMessage, setErrorMessage] = React.useState('')

  React.useEffect(() => {
    const tokenjs =
      process.env.GATSBY_ENVIRONMENT !== 'production'
        ? 'https://beta.epsilon.jp/js/token.js'
        : 'https://secure.epsilon.jp/js/token.js'
    const tokenjsEl = document.createElement('script')
    tokenjsEl.src = tokenjs
    tokenjsEl.async = true
    tokenjsEl.onload = () => {}
    document.body.appendChild(tokenjsEl)
  }, [])

  const getToken = React.useCallback((values: CreditCard) => {
    let year = Number(values.ccExpYear)
    if (year < 100) {
      year = year + 2000
    }

    const exp = `${String(year)}${values.ccExpMonth}`
    const cardObj = {
      cardno: values.ccNumber,
      expire: exp,
      securitycode: values.ccCsc,
      holdername: values.ccName,
    }

    const execTrade = (response: any) => {
      if (response.resultCode !== '000') {
        switch (response.resultCode) {
          // Ref: https://hapicode.com/doc/gmo-token.html#%E5%87%A6%E7%90%86%E7%B5%90%E6%9E%9C%E3%82%B3%E3%83%BC%E3%83%88%E3%82%99%E4%B8%80%E8%A6%A7
          case 100:
            setErrorMessage('カード番号が未入力です')
            return
          case 101:
            setErrorMessage('カード番号に半角数字以外が含まれています')
            return
          case 102:
            setErrorMessage('カード番号の桁数が少なすぎるか、多すぎます')
            return
          case 110:
            setErrorMessage('有効期限が未入力です')
            return
          case 111:
            setErrorMessage('有効期限に半角数字以外が含まれています')
            return
          case 112:
            setErrorMessage('有効期限が4桁または6桁以外になっています')
            return
          case 113:
            setErrorMessage('有効期限月が無効な値です（1～12以外）')
            return
          case 121:
            setErrorMessage('CVV/CVCに半角数字以外が含まれています')
            return
          case 122:
            setErrorMessage('CVV/CVCの桁数が少なすぎるか、多すぎます')
            return
          case 131:
            setErrorMessage('名義に半角英数字、一部の記号以外が含まれています')
            return
          case 132:
            setErrorMessage('名義は50文字までです')
            return
          default:
            setErrorMessage('')
        }
      }
      setToken(response.tokenObject.token)
      setMaskedCardNo(response.tokenObject.maskedCardNo)
    }

    window.execTrade = execTrade
    window.EpsilonToken.init('64758320')
    // execTrade とする場合と 'execTrade' とする場合がある。（ドキュメントが無いので詳細不明）
    window.EpsilonToken.getToken(cardObj, 'execTrade')
  }, [])

  return { getToken, token, maskedCardNo, errorMessage }
}
