import { HitlabelState, Action } from '@hitlabel.jp/main-site/types'
import React from 'react'
import { fetchHolidays, fetchProducts, fetchShippingDates } from '@hitlabel.jp/main-site/services'
import { ActionType } from '@hitlabel.jp/main-site/hooks/hitlabel/actions'

export const effects = (state: HitlabelState, dispatch: React.Dispatch<Action>) => {
  React.useEffect(() => {
    if (!window) {
      return
    }
    fetchHolidays().then(res => {
      dispatch({ type: ActionType.RECEIVE_HOLIDAYS, payload: res })
    })
    fetchShippingDates().then(res => {
      dispatch({ type: ActionType.RECEIVE_SHIPPING_DATES, payload: res })
    })
    // 5分毎に納期情報を更新する処理
    // window.setInterval(() => {
    //   fetchShippingDates().then(res => {
    //     dispatch({ type: ActionType.RECEIVE_SHIPPING_DATES, payload: res })
    //   })
    // }, 5 * 60 * 1000)
    fetchProducts().then(products => {
      dispatch({ type: ActionType.PRODUCTS_RECEIVE, payload: products })
    })
  }, [])
}
