import { http } from '../init'
import { User, LeaveData, APIError } from '@hitlabel.jp/main-site/types'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const requestUserData = async () => {
  return await http({
    url: '/v2/user',
    method: 'get',
  })
    .then(
      (res): User => {
        return camelcaseKeys(res.data.data.user, { deep: true })
      },
    )
    .catch(err => {
      return null
    })
}

export const userLeaveRequest = async (data: LeaveData) => {
  return await http({
    url: '/v2/leave',
    method: 'post',
    data: data,
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      return false
    })
}

export const userSignup = async (data: User) => {
  if (typeof data.postalCode === 'string') {
    data.postalCode = Number(data.postalCode.replace(/-/, '').trim()) as any
  }
  return await http({
    url: '/v2/user',
    method: 'post',
    data: snakecaseKeys(data, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      const apiError = err.response.data as APIError
      return apiError
    })
}

export const userAccountUpdate = async (data: User) => {
  data.postalCode = Number(data.postalCode.replace(/-/, '').trim()) as any
  return await http({
    url: '/v2/account',
    method: 'put',
    data: snakecaseKeys(data, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      return false
    })
}
