import { http } from '../init'
import { Address } from '@hitlabel.jp/main-site/types'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const requestSources = async () => {
  return await http({
    url: '/v2/sources',
    method: 'get',
  })
    .then((res): Address[] => {
      return camelcaseKeys(res.data.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const requestDestinations = async () => {
  return await http({
    url: '/v2/destinations',
    method: 'get',
  })
    .then((res): Address[] => {
      return camelcaseKeys(res.data.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const addDestination = async (values: Address) => {
  values.postalCode = Number(values.postalCode.replace('-', '')) as any
  values.id = 0
  return await http({
    url: '/v2/destinations',
    method: 'post',
    data: snakecaseKeys(values, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const updateDestination = async (values: Address) => {
  values.postalCode = Number(values.postalCode.replace('-', '')) as any
  return await http({
    url: `/v2/destinations/${values.id}`,
    method: 'put',
    data: snakecaseKeys(values, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const deleteDestination = async (id: number) => {
  return await http({
    url: `/v2/destinations/${id}`,
    method: 'delete',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const addSource = async (values: Address) => {
  values.postalCode = Number(values.postalCode.replace('-', '')) as any
  values.id = 0
  return await http({
    url: '/v2/sources',
    method: 'post',
    data: snakecaseKeys(values, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const updateSource = async (values: Address) => {
  values.postalCode = Number(values.postalCode.replace('-', '')) as any
  return await http({
    url: `/v2/sources/${values.id}`,
    method: 'put',
    data: snakecaseKeys(values, { deep: true }),
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}

export const deleteSource = async (id: number) => {
  return await http({
    url: `/v2/sources/${id}`,
    method: 'delete',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
      return null
    })
}
