import { http } from './init'
import camelcaseKeys from 'camelcase-keys'

export const validateCoupon = async (couponCode: string, totalAmount: number) => {
  return await http({
    method: 'post',
    url: `/v2/coupons/${couponCode}`,
    data: {
      totalAmount: totalAmount,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  }).then(res => {
    if (res.status === 200) {
      return camelcaseKeys(res.data.data, { deep: true })
    }
  })
}
