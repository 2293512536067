import { http } from './init'

export const requestAddressFromPostalCode = async (postalCode: string) => {
  return await http({
    url: `/v2/address/${postalCode}`,
    method: 'get',
  })
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
      return null
    })
}
