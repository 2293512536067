import React, { useCallback } from 'react'
import { ActionType } from '../actions'
import { CartItem, Action, MaterialYaml } from '@hitlabel.jp/main-site/types'
import { requestEstimatePdf, calcprice, downloadAutoEstimatePdf } from '@hitlabel.jp/main-site/services'
import toast from 'react-hot-toast'

export const methods = (dispatch: React.Dispatch<Action>) => {
  // useCallbackのdependencyにdispatchを入れるとものすごく重くなるので注意
  const currentItemReset = useCallback((data: MaterialYaml) => {
    dispatch({ type: ActionType.CURRENT_ITEM_RESET, payload: data })
    dispatch({ type: ActionType.GA4_VIEW_ITEM, payload: data })
  }, [])
  const currentItemChangeY = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_Y, payload: { value } })
  }, [])
  const currentItemChangeX = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_X, payload: { value } })
  }, [])
  const currentItemChangeSize = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_SIZE, payload: value })
  }, [])
  const currentItemChangeName = useCallback((value: string, nameError: string) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_NAME, payload: { value, nameError } })
  }, [])
  const currentItemChangeAdhesive = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_ADHESIVE, payload: { value } })
  }, [])
  const currentItemChangeLamination = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_LAMINATION, payload: { value } })
  }, [])
  const currentItemChangeHalfcut = useCallback((value: number, additionalDays: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_HALFCUT, payload: { value, additionalDays } })
  }, [])
  const currentItemChangeFormat = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_FORMAT, payload: { value } })
  }, [])
  const currentItemChangeCutlineService = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_CUTLINE_SERVICE, payload: { value } })
  }, [])
  const currentItemChangeCutpath = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_CUTPATH, payload: { value } })
  }, [])
  const currentItemChangeWhiteInk = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_WHITE_INK, payload: { value } })
  }, [])
  const currentItemChangeWhitedataService = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_WHITEDATA_SERVICE, payload: { value } })
  }, [])
  const currentItemChangeSlit = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_SLIT, payload: { value } })
  }, [])
  const currentItemChangeBackside = useCallback((value: number, additionalDays: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_BACKSIDE, payload: { value, additionalDays } })
  }, [])
  const currentItemChangeOndemandFoil = useCallback((value: number, additionalDays: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_ONDEMAND_FOIL, payload: { value, additionalDays } })
  }, [])
  const currentItemChangeDeliveryForm = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_DELIVERY_FORM, payload: { value } })
  }, [])
  const currentItemChangeSendCopy = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_SEND_COPY, payload: { value } })
  }, [])
  const currentItemChangeDeliveryDateProduct = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_DELIVERY_DATE_PRODUCT, payload: { value } })
  }, [])
  const currentItemChangeNum = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_NUM, payload: { value } })
  }, [])
  const currentItemAddToCart = useCallback((item: CartItem) => {
    calcprice(item).then(res => {
      if (res.data === null) {
        toast('ご指定の条件ではご注文いただけません', { icon: '⚠' })
      } else {
        dispatch({ type: ActionType.CURRENT_ITEM_ADD_TO_CART, payload: { item: item, calcResult: res.data } })
        dispatch({ type: ActionType.GA4_ADD_TO_CART, payload: res.data })
      }
    })
  }, [])
  const currentItemRequestEstimatePdf = useCallback((to: string, items: CartItem[]) => {
    requestEstimatePdf(to, items)
      .then(res => {
        if (res.url) {
          downloadAutoEstimatePdf(res.url, res.fileName)
        }
      })
      .catch(err => {
        console.log(err)
      })
    dispatch({ type: ActionType.CURRENT_ITEM_REQUEST_ESTIMATE_PDF })
  }, [])
  const currentItemChangeIndividualPackaging = useCallback((value: number) => {
    dispatch({ type: ActionType.CURRENT_ITEM_CHANGE_INDIVIDUAL_PACKAGING, payload: { value } })
  }, [])
  return {
    currentItemReset,
    currentItemChangeY,
    currentItemChangeX,
    currentItemChangeSize,
    currentItemChangeName,
    currentItemChangeAdhesive,
    currentItemChangeLamination,
    currentItemChangeHalfcut,
    currentItemChangeFormat,
    currentItemChangeCutlineService,
    currentItemChangeCutpath,
    currentItemChangeWhiteInk,
    currentItemChangeWhitedataService,
    currentItemChangeSlit,
    currentItemChangeBackside,
    currentItemChangeOndemandFoil,
    currentItemChangeDeliveryForm,
    currentItemChangeSendCopy,
    currentItemChangeDeliveryDateProduct,
    currentItemChangeNum,
    currentItemAddToCart,
    currentItemRequestEstimatePdf,
    currentItemChangeIndividualPackaging,
  }
}
