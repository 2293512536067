import React, { useCallback } from 'react'
import { ActionType } from '../actions'
import { Action, CartItem } from '@hitlabel.jp/main-site/types'
import { requestEstimatePdf, downloadAutoEstimatePdf } from '@hitlabel.jp/main-site/services'

export const methods = (dispatch: React.Dispatch<Action>) => {
  const cartClear = useCallback(async () => {
    dispatch({ type: ActionType.CART_CLEAR })
  }, [])
  const cartItemDuplicate = useCallback(async (id: string) => {
    dispatch({ type: ActionType.CART_ITEM_DUPLICATE, payload: { id: id } })
  }, [])
  const cartItemDelete = useCallback(async (id: string) => {
    dispatch({ type: ActionType.CART_ITEM_DELETE, payload: { id: id } })
  }, [])
  const cartItemChangeName = useCallback(async (id: string, name: string, nameError: string) => {
    dispatch({ type: ActionType.CART_ITEM_CHANGE_NAME, payload: { id: id, name: name, nameError: nameError } })
  }, [])
  const cartItemChangeShippingDate = useCallback(async (id: string, deliveryDate: number) => {
    dispatch({ type: ActionType.CART_ITEM_CHANGE_SHIPPING_DATE, payload: { id: id, deliveryDate: deliveryDate } })
  }, [])
  const cartItemChangeNum = useCallback(async (id: string, num: number) => {
    dispatch({ type: ActionType.CART_ITEM_CHANGE_NUM, payload: { id: id, num: num } })
  }, [])
  const cartItemChangeX = useCallback(async (id: string, x: number) => {
    dispatch({ type: ActionType.CART_ITEM_CHANGE_X, payload: { id: id, x: x } })
  }, [])
  const cartItemChangeY = useCallback(async (id: string, y: number) => {
    dispatch({ type: ActionType.CART_ITEM_CHANGE_Y, payload: { id: id, y: y } })
  }, [])
  const cartRequestEstimatePdf = useCallback((to: string, items: CartItem[]) => {
    requestEstimatePdf(to, items)
      .then(res => {
        if (res.url) {
          downloadAutoEstimatePdf(res.url, res.fileName)
        }
      })
      .catch(err => {
        console.log(err)
      })
    dispatch({ type: ActionType.CURRENT_ITEM_REQUEST_ESTIMATE_PDF })
  }, [])
  return {
    cartClear,
    cartItemDuplicate,
    cartItemDelete,
    cartItemChangeName,
    cartItemChangeShippingDate,
    cartItemChangeNum,
    cartItemChangeX,
    cartItemChangeY,
    cartRequestEstimatePdf,
  }
}
