import type { Checkout, PurchaseResponse } from '@hitlabel.jp/main-site/types'
import { http } from './init'
import camelcaseKeys from 'camelcase-keys'

export const sendPurchaseRequest = async (data: Checkout) => {
  data.items?.forEach(item => {
    // サーバーサイドはestimateDataはjson文字列である必要がある
    item.estimateData = JSON.stringify(item.estimateData) as any
  })
  return await http({
    method: 'post',
    url: '/v2/purchase',
    data: data,
  })
    .then(res => {
      if (res.status === 200) {
        // 逆
        if (res.data.data.estimateData && res.data.data.estimateData !== '') {
          try {
            res.data.data.estimateData = JSON.parse(res.data.data.estimateData)
          } catch (e) {
            res.data.data.estimateData = ""
          }
        }
        return camelcaseKeys(res.data.data, { deep: true }) as PurchaseResponse
      }
    })
    .catch(err => {
      return err
    })
}
