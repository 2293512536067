import { http } from './init'
import camelcaseKeys from 'camelcase-keys'

export const requestInformations = async () => {
  return await http({
    url: '/v2/info',
    method: 'get',
  })
    .then(res => {
      return camelcaseKeys(res.data.hits, { deep: true })
    })
    .catch(err => {
      return null
    })
}
