import { http } from './init'
import { CartItem, Nums } from '@hitlabel.jp/main-site/types'

export const getNums = async (items: CartItem[]) => {
  let response: Nums[]
  return await http({
    method: 'post',
    url: '/v2/nums',
    data: JSON.stringify(items),
  })
    .then(res => {
      if (res.status === 200) {
        response = res.data.data
        return response
      }
      return response
    })
    .catch(err => {
      console.error(err)
      return response
    })
}
