import { HitlabelState, ShippingDate, Holiday, Product, MaterialYamlConnection } from '@hitlabel.jp/main-site/types'

export const locationChanged = (payload: Location, state: HitlabelState) => {
  const newState = { ...state }
  newState.menuOpen = false
  const l = payload
  l.pathname = l.pathname.replace(/^\/sp/, '')
  newState.location = l
  newState.userMenuOpen = false
  return newState
}

export const receiveHolidays = (payload: Holiday[], state: HitlabelState) => {
  const newState = { ...state }
  newState.holidays = payload
  return newState
}

export const receiveShippingDates = (payload: ShippingDate[], state: HitlabelState) => {
  const newState = { ...state }
  newState.shippingDates = payload
  return newState
}

export const productsReceive = (payload: Product[], state: HitlabelState) => {
  const newState = { ...state }
  newState.products = payload
  return newState
}

export const loadMaterials = (payload: MaterialYamlConnection, state: HitlabelState) => {
  const newState = { ...state }
  newState.materials = payload.nodes
  return newState
}
