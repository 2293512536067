export const LS_PREFIX = 'hitlabel20221116001'

export const HLCONST = {
  orderStatus: {
    unknown: 0,
    ordered: 1,
    purchased: 2,
    uploaded: 3,
    waitingReUpload: 4,
    datachecked: 5,
    printed: 7,
    shipped: 8,
    delivered: 9,
    waitingPurchase: 10,
    waitingResponse: 12,
    cancelRequested: 90,
    paybackedAll: 91,
    paybacked: 92,
    canceled: 99,
  },
  sampleImage: {
    loading: 0,
    disabled: 1,
    waiting: 2,
    uploaded: 3,
    exists: 4,
    canceled: 9,
  },
}
