import React from 'react'
export const useScrollToError = (formState: any) => {
  React.useEffect(() => {
    if (!formState.isSubmitting) {
      return
    }
    if (formState.errors.length === 0) {
      return
    }
    let mostTop: { top: number; el?: Element } = {
      top: Number.MAX_SAFE_INTEGER,
    }
    Object.keys(formState.errors).map(key => {
      const el = document.querySelector(`[data-input-name="${key}"]`)
      if (!el) {
        return
      }
      const rect = el.getBoundingClientRect()
      if (rect.top < mostTop.top) {
        mostTop = {
          top: rect.top,
          el: el,
        }
      }
    })
    if (mostTop.el) {
      mostTop.el.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
    }
  }, [formState.isSubmitted, formState.isSubmitting, formState.errors])
}
