import { HitlabelState } from '@hitlabel.jp/main-site/types'

export const cardTokenReceive = (payload: any, state: HitlabelState) => {
  const checkoutState = state.checkout
  const newCheckoutState = {
    ...checkoutState,
    card: {
      token: payload.token,
      maskedCardNo: payload.maskedCardNo,
      installment: checkoutState.card.installment,
      errorMessage: '',
    },
  }
  return { ...state, requesting: false, checkout: newCheckoutState }
}

export const cardTokenError = (payload: string, state: HitlabelState) => {
  const checkoutState = state.checkout
  const newCheckoutState = {
    ...checkoutState,
    card: { token: '', maskedCardNo: '', installment: '', errorMessage: payload },
  }
  return { ...state, requesting: false, checkout: newCheckoutState }
}
