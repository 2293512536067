import { http } from './init'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import { dayjs } from '@hitlabel.jp/main-site/utils'
import { Holiday, ContactForm } from '@hitlabel.jp/main-site/types'

export const fetchHolidays = async () => {
  return await http({
    url: '/v2/holidays',
    method: 'get',
  })
    .then(res => {
      const holidays = camelcaseKeys(res.data.data).map((h: Holiday) => {
        return {
          id: h.id,
          day: dayjs(Number(h.day) * 1000),
          description: h.description,
        }
      })
      return holidays
    })
    .catch(err => {
      return null
    })
}

export const fetchShippingDates = async () => {
  return await http({
    url: '/v2/shipping-dates',
    method: 'get',
  })
    .then(res => {
      return camelcaseKeys(res.data, { deep: true })
    })
    .catch(err => {
      return null
    })
}

export const sendContact = async (data: ContactForm) => {
  const snaked = snakecaseKeys(data, { deep: true })
  return http({
    url: '/v2/contact',
    method: 'post',
    data: snaked,
  })
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
    })
}
