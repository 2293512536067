import React from 'react'
import { HitlabelProvider } from '@hitlabel.jp/main-site/context'
import { useHitlabel } from '@hitlabel.jp/main-site/hooks/hitlabel'
import { setLocale } from 'yup'
import toast, { Toaster } from 'react-hot-toast'
import './sentry.config'
import isMobile from 'ismobilejs'

if (!isMobile().phone && ['production', 'staging'].includes(process.env.GATSBY_ENVIRONMENT)) {
  window.location.href = '/'
}

window.HitLabel = {
  specScroll: true,
}
window.toast = toast

const Root = ({ element }) => {
  const hitlabel = useHitlabel()
  return (
    <HitlabelProvider value={hitlabel}>
      {element}
      <Toaster
        position="top-right"
        toastOptions={{ className: 'font-bold text-xs rounded', style: { padding: '4px 8px' } }}
      />
    </HitlabelProvider>
  )
}

export const wrapRootElement = ({ element }) => (
  <>
    <Root element={element} />
  </>
)

// // Tab無効化
// window.onkeydown = function (event) {
//   if (event.keyCode === 9) {
//     event.preventDefault()
//   }
// }

setLocale({
  mixed: {
    default: '値に誤りがあります',
    required: '必須項目です',
    notType: '形式が違います',
  },
  number: {
    integer: '数値が必要です',
    min: '小さすぎます',
    max: '大きすぎます',
  },
  string: {},
})
