import {
  HitlabelState,
  CartItem,
  MaterialYaml,
  Nums,
  CalcResult,
  MaterialYamlSpecification,
  CartItemFormState,
} from '@hitlabel.jp/main-site/types'
import { initialCurrentItem } from '@hitlabel.jp/main-site/hooks/hitlabel/initial-state'
import { navigate } from 'gatsby'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'

export const currentItemInit = (state: HitlabelState) => {
  const newState = { ...state }
  newState.currentItem = initialCurrentItem()
  return newState
}

export const currentItemReset = (payload: MaterialYaml, state: HitlabelState) => {
  const newID = new Date().getTime()
  const currentCurrentItem = initialCurrentItem()
  const formState = currentCurrentItem.form
  currentCurrentItem.productType = payload.productType as any
  const spec = payload.specification as any
  let numSelections = 0
  if (payload.productType === 'general' || payload.productType === 'bigsticker') {
    Object.keys(payload.specification as MaterialYamlSpecification).map(key => {
      if (payload.specification) {
        const s = spec[key] as any
        if (s && formState[key] !== undefined) {
          if (s.disabled === false) {
            numSelections++
          } else {
            formState[key].touched = true
          }
        }
      }

      // 営業日の初期値を反映する
      if (key === 'deliveryDateProduct') {
        switch (spec[key].default) {
          case 'day7':
            currentCurrentItem.deliveryDateProduct = 7
            break
          case 'day5':
            currentCurrentItem.deliveryDateProduct = 5
            break
          case 'day3':
            currentCurrentItem.deliveryDateProduct = 3
            break
        }
      }
    })
    formState.size.active = true
  } else {
    formState.shape.active = true
  }
  formState.deliveryDate.touched = true
  const newCurrentItemState: CartItem = {
    ...currentCurrentItem,
    id: newID,
    product: {
      id: Number(payload.productId),
    },
    spec: payload.specification,
    form: formState,
    selectProgress: {
      num: numSelections,
      touched: 0,
    },
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeX = (payload: { value: number }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newCurrentItemState = {
    ...currentItemState,
    sizeX: payload.value,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeY = (payload: { value: number }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newCurrentItemState = {
    ...currentItemState,
    sizeY: payload.value,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeName = (payload: { value: string; nameError: string }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newCurrentItemState = {
    ...currentItemState,
    name: payload.value,
    nameError: payload.nameError,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemReceiveSize = (payload: number, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newFormState = currentItemState.form
  newFormState.size.touched = true
  const newCurrentItemState = {
    ...currentItemState,
    size: payload ? payload : 0,
    form: newFormState,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemReceiveShape = (
  payload: { id: number; name: string; sealSize: number },
  state: HitlabelState,
) => {
  const currentItemState = state.currentItem
  const newFormState = currentItemState.form
  newFormState.size.touched = true
  const newCurrentItemState = {
    ...currentItemState,
    size: payload ? payload.sealSize : 0,
    shape: payload ? payload.name : '',
    form: newFormState,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemReceiveNums = (payload: Nums, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newCurrentItemState = {
    ...currentItemState,
    enabledNums: payload,
  }
  const max = Math.max(...payload.nums)
  if (currentItemState.num > max) {
    newCurrentItemState.num = max
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeNum = (payload: { value: number }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const currentForm = currentItemState.form
  currentForm.num.touched = true
  const newCurrentItemState = {
    ...currentItemState,
    num: payload.value,
    form: { ...currentForm },
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeIndividualPackaging = (payload: { value: number }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const currentForm = currentItemState.form
  currentForm.individualPackaging.touched = true
  const newCurrentItemState = {
    ...currentItemState,
    individualPackaging: payload.value,
    form: { ...currentForm },
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeDeliveryDateProduct = (payload: { value: number }, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const currentForm = currentItemState.form
  currentForm.deliveryDate.touched = true
  const newCurrentItemState = {
    ...currentItemState,
    deliveryDateProduct: payload.value,
    form: { ...currentForm },
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemChangeOption = (
  payload: { value: number; additionalDays?: number },
  state: HitlabelState,
  name: string,
) => {
  const currentItemState = state.currentItem
  const currentForm = currentItemState.form
  if (payload.value !== -999) {
    // -999ではない時は通常通り更新する
    currentForm[name].touched = true
    currentForm[name].additionalDays = payload.additionalDays || 0
    const newCurrentItemState = {
      ...currentItemState,
      [name]: { id: payload.value },
      form: { ...currentForm },
    }
    return { ...state, currentItem: newCurrentItemState }
  }
  // value: -999 の時その項目を初期化する
  currentForm[name].touched = false
  currentForm[name].additionalDays = 0
  const newCurrentItemState = {
    ...currentItemState,
    [name]: undefined,
    form: { ...currentForm },
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemRequestPrice = (state: HitlabelState) => {
  return { ...state }
}
export const currentItemReceivePrice = (payload: CalcResult, state: HitlabelState) => {
  const currentItemState = state.currentItem
  const newCurrentItemState = {
    ...currentItemState,
    calcResult: payload,
  }
  return { ...state, currentItem: newCurrentItemState }
}

export const currentItemAddToCart = (payload: { item: CartItem; calcResult: CalcResult }, state: HitlabelState) => {
  const { item, calcResult } = payload
  const newState = { ...state }
  const names = Object.keys(state.currentItem.form)
  for (const n of names) {
    if (state.currentItem.form[n].error !== '') {
      window.alert('選択項目をご確認ください')
      return state
    }
  }
  const newCartItem = { ...(calcResult.cartItem || item) }
  newCartItem.calcResult = { ...calcResult }
  // newCartItem.calcResult.cartItem = undefined // これなんぞ？
  newCartItem.id = new Date().getTime()
  newCartItem.name = state.currentItem.name
  newCartItem.nameError = state.currentItem.nameError
  newCartItem.enabledNums = state.currentItem.enabledNums
  newCartItem.productType = state.currentItem.productType
  newCartItem.spec = state.currentItem.spec
  newState.cart.items.push(newCartItem)
  localStorage.setItem(`${LS_PREFIX}:cart`, JSON.stringify(newState.cart))
  newState.currentItem = initialCurrentItem()
  navigate('/cart')
  return newState
}

export const currentItemSetProgress = (
  payload: { form: CartItemFormState; selectProgress: { num: number; touched: number } },
  state: HitlabelState,
) => {
  const f = payload.form
  const p = payload.selectProgress
  const s = { ...state }
  const c = s.currentItem.form
  for (const n of Object.keys(f)) {
    c[n].active = f[n].active
  }
  s.currentItem.form = c
  s.currentItem.selectProgress = p
  return s
}

export const currentItemSetErrorSize = (payload: { status: boolean; message: string }, state: HitlabelState) => {
  const newState = { ...state }
  newState.currentItem.form.size.error = payload.message
  newState.currentItem.size = 0
  return newState
}

export const currentItemChangeSize = (payload: number, state: HitlabelState) => {
  const newState = { ...state }
  newState.currentItem.size = payload
  newState.currentItem.form.shape.touched = true
  return newState
}

export const currentItemSetDeliveryDateOption = (state: HitlabelState) => {
  const newState = { ...state }
  let d = 0
  Object.keys(newState.currentItem.form).forEach(a => {
    const days = newState.currentItem.form[a].additionalDays
    if (days) {
      d = d + days
    }
  })
  newState.currentItem.deliveryDateOption = d
  return newState
}
