import {
  HitlabelState,
  Purchase,
  User,
  Address,
  Estimate,
  CartItem,
  EstimateResponse,
  EstimateData,
  CalcResult,
} from '@hitlabel.jp/main-site/types'
import {
  initialCurrentItem,
  initialCalcResult,
  initialCalcDetail,
} from '@hitlabel.jp/main-site/hooks/hitlabel/initial-state'
import camelcaseKeys from 'camelcase-keys'
import toast from 'react-hot-toast'
import { initialState } from '@hitlabel.jp/main-site/hooks/hitlabel/initial-state'
import { navigate } from 'gatsby'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'

export const userAccountRequest = (state: HitlabelState) => {
  return { ...state }
}
export const userAccountReceive = (payload: User, state: HitlabelState) => {
  return { ...state, user: payload }
}

export const userShipmentInitAddressFormDefaultValues = (state: HitlabelState) => {
  const newState = { ...state }
  newState.addressFormDefaultValues = {
    id: '',
    postalCode: '',
    addressLevel1: '',
    addressLevel2: '',
    addressLine1: '',
    addressLine2: '',
    organization: '',
    familyName: '',
    givenName: '',
    familyKana: '',
    givenKana: '',
    tel: '',
  }
  return newState
}
export const userShipmentSetAddressFormDefaultValues = (payload: Address, state: HitlabelState) => {
  const newState = { ...state }
  newState.addressFormDefaultValues = payload
  return newState
}
export const userShipmentRequestDestinations = (state: HitlabelState) => {
  return { ...state }
}
export const userShipmentReceiveDestinations = (payload: Address[], state: HitlabelState) => {
  const userState = state.user
  userState.destinations = payload
  return { ...state, user: userState }
}

export const userShipmentRequestSources = (state: HitlabelState) => {
  return { ...state }
}
export const userShipmentReceiveSources = (payload: Address[], state: HitlabelState) => {
  const userState = state.user
  userState.sources = payload
  return { ...state, user: userState }
}

export const userShipmentAddDestination = (state: HitlabelState) => {
  return { ...state }
}
export const userShipmentUpdateDestination = (payload: Address, state: HitlabelState) => {
  return { ...state }
}
export const userShipmentDeleteDestination = (payload: Address, state: HitlabelState) => {
  return { ...state }
}

export const userShipmentAddSource = (state: HitlabelState) => {
  return { ...state }
}
export const userShipmentUpdateSource = (payload: Address, state: HitlabelState) => {
  return { ...state }
}
export const userShipmentDeleteSource = (payload: Address, state: HitlabelState) => {
  return { ...state }
}

export const userCurrentPurchaseReceive = (payload: Purchase, state: HitlabelState) => {
  const newState = { ...state }
  newState.user.currentPurchase = payload
  return newState
}

export const userActiveOrdersReceive = (payload: Purchase[], state: HitlabelState) => {
  const newState = { ...state }
  newState.user.activeOrders = payload
  return newState
}

export const userDeliveredOrdersReceive = (payload: Purchase[], state: HitlabelState) => {
  const newState = { ...state }
  newState.user.deliveredOrders = payload
  return newState
}

export const userEstimatesReceive = (payload: Estimate[], state: HitlabelState) => {
  const newState = { ...state }
  newState.user.activeEstimates = payload
  return newState
}

export const userPurchasesReceive = (payload: Purchase[], state: HitlabelState) => {
  const newState = { ...state }
  newState.user.histories = payload
  return newState
}

export const userUploadFormOpen = (payload: { orderId: number; orderName: string }, state: HitlabelState) => {
  const newState = { ...state }
  newState.user.uploadForm = {
    open: true,
    orderId: payload.orderId,
    orderName: payload.orderName,
  }
  return newState
}

export const userUploadFormClose = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.uploadForm = undefined
  return newState
}

export const userCancelFormOpen = (
  payload: { id: number; purchaseId: number; cartItems: CartItem[] },
  state: HitlabelState,
) => {
  const newState = { ...state }
  newState.user.cancelForm = {
    open: true,
    id: payload.id,
    purchaseId: payload.purchaseId,
    cartItems: payload.cartItems,
  }
  return newState
}

export const userCancelFormClose = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.cancelForm = undefined
  return newState
}

export const userPurchaseRequestCancel = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const userPurchaseDoneCancel = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.cancelForm = undefined
  newState.requesting = false
  return newState
}

export const userChangeShipmentFormOpen = (payload: Purchase, state: HitlabelState) => {
  const newState = { ...state }
  newState.user.changeShipmentForm = {
    open: true,
    purchase: payload,
  }
  return newState
}

export const userChangeShipmentFormClose = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.changeShipmentForm = undefined
  return newState
}

export const userInvoiceFormOpen = (payload: Purchase, state: HitlabelState) => {
  const newState = { ...state }
  newState.user.invoiceForm = {
    open: true,
    purchase: payload,
  }
  return newState
}

export const userInvoiceFormClose = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.invoiceForm = undefined
  return newState
}

export const userReceiptFormOpen = (payload: Purchase, state: HitlabelState) => {
  console.log(payload)
  const newState = { ...state }
  newState.user.receiptForm = {
    open: true,
    purchase: payload,
  }
  return newState
}

export const userReceiptFormClose = (state: HitlabelState) => {
  const newState = { ...state }
  newState.user.receiptForm = undefined
  return newState
}

export const userPurchaseChangeShipmentRequest = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const userPurchaseChangeShipmentDone = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  return newState
}

export const userPurchaseRequestInvoice = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const userPurchaseReceiveInvoice = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  return newState
}

export const userPurchaseRequestReceipt = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const userPurchaseReceiveReceipt = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  return newState
}

export const userOrderRepeat = (payload: { item: CartItem; calcResult: CalcResult }, state: HitlabelState) => {
  const { item, calcResult } = payload
  const newState = { ...state }
  if (item.repeatId === 0) {
    if (calcResult.order.repeatId !== 0) {
      item.repeatId = calcResult.order.repeatId
    } else {
      item.repeatId = calcResult.order.orderId
    }
  }
  const materials = state.materials || []
  for (const m of materials) {
    if (m.productId === item.product?.id) {
      item.spec = m.specification
    }
  }
  item.calcResult = calcResult
  item.id = new Date().getTime()
  item.shippingDate = 0
  newState.cart.items.push(item)
  toast.success('カートに追加されました')
  return newState
}

export const userEstimateToCart = (payload: { estimate: Estimate; childNumber: number }, state: HitlabelState) => {
  const newState = { ...state }
  const res: EstimateResponse = camelcaseKeys(JSON.parse(payload.estimate.response), { deep: true })
  const child = res.children.filter(c => {
    return c.no === payload.childNumber
  })[0]
  const data: EstimateData = {
    child: child,
    estimate: {
      estimateNumber: payload.estimate.estimateNumber,
    },
  }
  const cartItem: CartItem = {
    ...initialCurrentItem(),
    product: { id: 159 },
    calcResult: {
      ...initialCalcResult,
      detail: {
        ...initialCalcDetail,
        amountTotal: child.amountWithTax,
      },
    },
    type: 'estimate',
    estimate: payload.estimate,
    estimateData: data,
  }
  cartItem.id = new Date().getTime()
  newState.cart.items.push(cartItem)
  toast.success('カートに追加されました')
  return newState
}

export const userLeaveDone = (state: HitlabelState) => {
  localStorage.removeItem(`${LS_PREFIX}:token`)
  const newState = initialState()
  navigate('/')
  toast.success('退会処理が完了しました')
  return newState
}

export const userAccountRequestUpdate = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}

export const userAccountDoneUpdate = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  navigate('/mypage/account-done')
  toast.success('アカウント情報の更新が完了しました')
  return newState
}

export const userAccountFailUpdate = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  toast.error('サーバーでエラーが発生しました')
  return newState
}
