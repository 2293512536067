import { ActionType } from '../actions'
import { HitlabelState, CalcResult, MaterialYaml, Purchase } from '@hitlabel.jp/main-site/types'

export const ga4Event = (action: any, state: HitlabelState) => {
  if (!window) {
    return state
  }

  switch (action.type) {
    case ActionType.GA4_VIEW_CART:
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_cart',
        ecommerce: {
          currency: 'JPY',
          value: state.cart.totalAmount,
          items: state.cart.items.map(item => {
            return {
              item_id: item.type === 'estimate' ? '0' : String(item.product?.id),
              item_name: item.product?.name,
              price: item.amountTotal,
              currency: 'JPY',
            }
          }),
        },
      })
      break
    case ActionType.GA4_BEGIN_CHECKOUT:
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'begin_checkout',
        ecommerce: {
          currency: 'JPY',
          value: state.cart.totalAmount,
          items: state.cart.items.map(item => {
            return {
              item_id: item.type === 'estimate' ? '0' : String(item.product?.id),
              item_name: item.product?.name,
              price: item.amountTotal,
              currency: 'JPY',
            }
          }),
        },
      })
      break
    case ActionType.GA4_SIGN_UP:
      window.dataLayer.push({
        event: 'sign_up',
        ads_conversion: {
          email: action.payload.email,
        },
      })
      break
    case ActionType.GA4_LOGIN:
      window.dataLayer.push({
        event: 'login',
      })
      break
    case ActionType.GA4_ADD_TO_CART:
      const addToCartPayload = action.payload as CalcResult
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          currency: 'JPY',
          value: addToCartPayload.detail.amountTotal,
          items: [
            {
              item_id: addToCartPayload.cartItem?.product?.id,
              item_name: addToCartPayload.cartItem?.product?.name,
            },
          ],
        },
      })
      break
    case ActionType.GA4_REMOVE_FROM_CART:
      // remove_from_cart は削除処理と非同期にできないので reducers/cart.ts で扱う
      break
    case ActionType.GA4_VIEW_ITEM:
      const viewItemPayload = action.payload as MaterialYaml
      if (!viewItemPayload.productId || !viewItemPayload.displayName) break
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'view_item',
        ecommerce: {
          currency: 'JPY',
          items: [
            {
              item_id: viewItemPayload.productId,
              item_name: viewItemPayload.displayName,
            },
          ],
        },
      })
      break
    case ActionType.GA4_PURCHASE:
      const purchasePayload = action.payload as Purchase
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'purchase',
        ecommerce: {
          currency: 'JPY',
          transaction_id: purchasePayload.purchaseId,
          value: purchasePayload.totalAmount,
          coupont: purchasePayload.usedCoupon,
          items: purchasePayload.cartItems.map(item => {
            return {
              item_id: item.product?.id,
              item_name: item.product?.name,
              value: item.amountTotal,
            }
          }),
        },
        ads_conversion: {
          email: state.user.email,
        },
      })
      break
  }
  const newState = { ...state }
  return newState
}
