import React from 'react'

// ref: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export const useDebounce = (value: string | number) => {
  const [debouncedValue, setDebouncedValue] = React.useState<string | number>(value)
  React.useEffect(() => {
    const delay = 500
    const timeoutId = window.setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => {
      window.clearTimeout(timeoutId)
    }
  }, [value])
  return { debouncedValue }
}
