import React from 'react'
import { CartItem } from '@hitlabel.jp/main-site/types'

export const useSpecProgress = (ref: React.RefObject<HTMLDivElement>, currentItem: CartItem) => {
  const [selectionNum, setSelectionNum] = React.useState(0)
  const [selectedNum, setSelectedNum] = React.useState(0)
  React.useEffect(() => {
    if (!ref.current) {
      return
    }
    const cards = ref.current.querySelectorAll('.spec-card')
    let selected = 0
    let num = 0
    cards.forEach((card: any) => {
      if (card.style.display === 'none') {
        return
      }
      num++
      if (card.ariaSelected === 'true') {
        selected++
      }
    })
    setSelectionNum(num)
    setSelectedNum(selected)
  }, [ref, JSON.stringify(currentItem)])
  return [selectionNum, selectedNum]
}
