export const orderStatusString = (status: number) => {
  switch (status) {
    case 0:
      return '不明'
    case 1:
      return 'ご注文済み'
    case 2:
      return 'お支払い済み'
    case 3:
      return 'ご入稿済み'
    case 4:
      return '再入稿待ち'
    case 5:
      return '印刷・加工中'
    case 7:
      return '印刷・加工中'
    case 8:
      return '発送済み'
    case 9:
      return '納品済み'
    case 10:
      return 'お支払い待ち'
    case 12:
      return 'お客様確認中'
    case 90:
      return 'キャンセル処理中'
    case 91:
      return 'キャンセル完了'
    case 92:
      return 'キャンセル完了'
    case 99:
      return 'キャンセル完了'
  }
}

export const purchaseMethodString = (name: string) => {
  switch (name) {
    case 'epsilon':
      return 'クレジットカード'
    case 'point':
      return '全額ポイント払い'
    case 'paypay':
      return 'PayPay'
    case 'vbank':
      return '銀行振込 - バーチャル口座'
    case 'bank':
      return '銀行振込 - 三菱UFJ銀行'
    case 'gmoab':
      return 'GMO後払い'
    case 'gmokb':
      return 'GMO掛け払い'
    case 'onDelivery':
      return '代金引換'
  }
}

export const deliveryTimeString = (deliveryTime: string) => {
  switch (deliveryTime) {
    case 'none':
      return '指定なし'
    case 'am':
      return '午前中'
    case 'pm':
      return '午後'
  }
}
