import React from 'react'
import { HitlabelState, Action } from '@hitlabel.jp/main-site/types'
import { ActionType } from '@hitlabel.jp/main-site/hooks/hitlabel/actions'
import { getSize, getShape, getNums, calcprice } from '@hitlabel.jp/main-site/services'
import { validateSize } from '@hitlabel.jp/main-site/validate'

export const effects = (state: HitlabelState, dispatch: React.Dispatch<Action>) => {
  /**
   *  location変更時にトップページや/products だった場合は currentItem を初期化する
   **/
  React.useEffect(() => {
    if (state.location && (state.location.pathname === '/' || state.location.pathname.startsWith('/products'))) {
      dispatch({ type: ActionType.CURRENT_ITEM_INIT })
    }
  }, [state.location?.pathname])
  /**
   * 材質、縦、横が変更されたらバリデーションしてOKだったら面積、または形状を取得する
   **/
  React.useEffect(() => {
    if (!state.currentItem.product?.id || state.currentItem.sizeX === 0 || state.currentItem.sizeY === 0) {
      return
    }
    const validationResult = validateSize(state.currentItem)
    dispatch({ type: ActionType.CURRENT_ITEM_SET_ERROR_SIZE, payload: validationResult })
    if (validationResult.status) {
      if (state.currentItem.productType === 'general') {
        getSize(state.currentItem)
          .then(res => {
            dispatch({ type: ActionType.CURRENT_ITEM_RECEIVE_SIZE, payload: res })
          })
          .catch(err => {
            console.error(err)
          })
      }
      if (state.currentItem.productType === 'bigsticker') {
        getShape(state.currentItem)
          .then(res => {
            dispatch({ type: ActionType.CURRENT_ITEM_RECEIVE_SHAPE, payload: res })
          })
          .catch(err => {
            console.error(err)
          })
      }
    }
  }, [state.currentItem.product?.id, state.currentItem.sizeX, state.currentItem.sizeY])
  /**
   * 材質、サイズ、納期が変更されたら枚数を取得する
   **/
  React.useEffect(() => {
    if (!state.currentItem.product?.id || state.currentItem.size === 0 || !state.currentItem.deliveryDateProduct) {
      return
    }
    getNums([state.currentItem])
      .then(res => {
        if (res.length > 0) {
          dispatch({ type: ActionType.CURRENT_ITEM_RECEIVE_NUMS, payload: res[0] })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [state.currentItem.product?.id, state.currentItem.size, state.currentItem.deliveryDateProduct])

  /**
   * 価格計算をリクエストする
   **/
  React.useEffect(() => {
    if (!state.currentItem || state.currentItem.num === 0) {
      return
    }
    dispatch({ type: ActionType.CURRENT_ITEM_REQUEST_PRICE })
    calcprice(state.currentItem)
      .then(res => {
        if (res.data) {
          dispatch({ type: ActionType.CURRENT_ITEM_RECEIVE_PRICE, payload: res.data })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [
    state.currentItem.num,
    state.currentItem.product,
    state.currentItem.lamination,
    state.currentItem.adhesive,
    state.currentItem.halfcut,
    state.currentItem.format,
    state.currentItem.cutpath,
    state.currentItem.slit,
    state.currentItem.backSide,
    state.currentItem.ondemandFoil,
    state.currentItem.deliveryForm,
    state.currentItem.whiteInk,
    state.currentItem.sendCopy,
    state.currentItem.cutlineService,
    state.currentItem.whitedataService,
    state.currentItem.size,
    state.currentItem.num,
    state.currentItem.deliveryDateProduct,
    state.currentItem.deliveryDateOption,
    state.currentItem.amountProduct,
    state.currentItem.amountOption,
    state.currentItem.amountTotal,
  ])

  /**
   * 前のフォームがタッチされたらアクティブにする
   */
  const f = state.currentItem.form
  React.useEffect(() => {
    const pathname = state.location ? state.location.pathname : ''
    if (!pathname.startsWith('/material')) {
      return
    }
    const f = state.currentItem.form
    if (state.currentItem.productType === 'general' || state.currentItem.productType === 'bigsticker') {
      let t = f.size.touched
      if (t) {
        f.adhesive.active = true
      }
      t = t && f.adhesive.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
      }
      t = t && f.ondemandFoil.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
      }
      t = t && f.whiteInk.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
      }
      t = t && f.whitedataService.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
      }
      t = t && f.lamination.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
      }
      t = t && f.format.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
      }
      t = t && f.cutlineService.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
      }
      t = t && f.halfcut.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
      }
      t = t && f.cutpath.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
      }
      t = t && f.slit.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
      }
      t = t && f.backSide.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
        f.deliveryForm.active = true
      }
      t = t && f.deliveryForm.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
        f.deliveryForm.active = true
        f.sendCopy.active = true
      }
      t = t && f.sendCopy.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
        f.deliveryForm.active = true
        f.sendCopy.active = true
        f.deliveryDate.active = true
      }
      t = t && f.deliveryDate.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
        f.deliveryForm.active = true
        f.sendCopy.active = true
        f.deliveryDate.active = true
        f.num.active = true
      }
      t = t && f.num.touched
      if (t) {
        f.adhesive.active = true
        f.ondemandFoil.active = true
        f.whiteInk.active = true
        f.whitedataService.active = true
        f.lamination.active = true
        f.format.active = true
        f.cutlineService.active = true
        f.halfcut.active = true
        f.cutpath.active = true
        f.slit.active = true
        f.backSide.active = true
        f.deliveryForm.active = true
        f.sendCopy.active = true
        f.deliveryDate.active = true
        f.num.active = true
        f.individualPackaging.active = true
      }
      const p = state.currentItem.selectProgress
      let touched = 0
      let numSelection = 0
      Object.keys(f).forEach(n => {
        numSelection++
        if (f[n].touched) {
          touched++
        }
      })
      p.touched = touched
      p.num = numSelection
      dispatch({ type: ActionType.CURRENT_ITEM_SET_PROGRESS, payload: { form: f, selectProgress: p } })
    }

    if (state.currentItem.productType === 'numbering') {
      let t = f.shape.touched
      if (t) {
        f.deliveryDate.active = true
      }
      t = t && f.deliveryDate.touched
      if (t) {
        f.deliveryDate.active = true
        f.num.active = true
      }
      const p = state.currentItem.selectProgress
      let touched = 0
      let numSelection = 0
      Object.keys(f).forEach(n => {
        numSelection++
        if (f[n].touched) {
          touched++
        }
      })
      p.touched = touched
      p.num = numSelection
      dispatch({ type: ActionType.CURRENT_ITEM_SET_PROGRESS, payload: { form: f, selectProgress: p } })
    }

    if (state.currentItem.productType === 'pop') {
      let t = f.shape.touched
      if (t) {
        f.deliveryDate.active = true
      }
      t = t && f.deliveryDate.touched
      if (t) {
        f.deliveryDate.active = true
        f.num.active = true
      }
      const p = state.currentItem.selectProgress
      let touched = 0
      let numSelection = 0
      Object.keys(f).forEach(n => {
        numSelection++
        if (f[n].touched) {
          touched++
        }
      })
      p.touched = touched
      p.num = numSelection
      dispatch({ type: ActionType.CURRENT_ITEM_SET_PROGRESS, payload: { form: f, selectProgress: p } })
    }

    if (state.currentItem.productType === 'embos') {
      let t = f.shape.touched
      if (t) {
        f.deliveryDate.active = true
      }
      t = t && f.deliveryDate.touched
      if (t) {
        f.deliveryDate.active = true
        f.num.active = true
      }
      const p = state.currentItem.selectProgress
      let touched = 0
      let numSelection = 0
      Object.keys(f).forEach(n => {
        numSelection++
        if (f[n].touched) {
          touched++
        }
      })
      p.touched = touched
      p.num = numSelection
      dispatch({ type: ActionType.CURRENT_ITEM_SET_PROGRESS, payload: { form: f, selectProgress: p } })
    }
  }, [
    state.location?.pathname,
    f.size.touched,
    f.adhesive.touched,
    f.whiteInk.touched,
    f.whitedataService.touched,
    f.lamination.touched,
    f.halfcut.touched,
    f.format.touched,
    f.cutlineService.touched,
    f.cutpath.touched,
    f.slit.touched,
    f.backSide.touched,
    f.ondemandFoil.touched,
    f.deliveryForm.touched,
    f.sendCopy.touched,
    f.deliveryDate.touched,
    f.num.touched,
    f.shape.touched,
    f.deliveryDate.touched,
  ])

  // オプションによる追加納期を計算
  React.useEffect(() => {
    dispatch({ type: ActionType.CURRENT_ITEM_SET_DELIVERY_DATE_OPTION })
  }, [JSON.stringify(state.currentItem.form)])

  // GA4 view_item
  React.useEffect(() => {
    if (!location.pathname.startsWith('/material')) {
      return
    }
    if (state.currentItem.product && state.currentItem.product.id > 0) {
      dispatch({ type: ActionType.GA4_VIEW_ITEM, payload: state.currentItem.product })
    }
  }, [state.currentItem.product?.id])
}
