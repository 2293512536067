import React from 'react'
import jsonp from 'jsonp'
import { UseFormReturn } from 'react-hook-form'

export const usePostalCode = (postalCode: string, context: UseFormReturn) => {
  const [addr, setAddr] = React.useState({
    addressLevel1: '',
    addressLevel2: '',
    addressLine1: '',
  })
  React.useEffect(() => {
    if (!postalCode) {
      return
    }
    if (String(postalCode).replace(/-/, '').length !== 7) {
      return
    }
    const url = `https://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
    jsonp(url, undefined, (err, data) => {
      if (data.results === null || data.results.length === 0) {
        return
      }
      if (err) {
        console.error(err)
      } else {
        const result = {
          addressLevel1: data.results[0].address1,
          addressLevel2: data.results[0].address2,
          addressLine1: data.results[0].address3,
        }
        setAddr(result)
      }
    })
  }, [postalCode])

  React.useEffect(() => {
    if (!addr) {
      return
    }
    if (addr.addressLevel1 !== '') {
      context.setValue('addressLevel1', addr.addressLevel1)
    }
    if (addr.addressLevel2 !== '') {
      context.setValue('addressLevel2', addr.addressLevel2)
    }
    if (addr.addressLine1 !== '') {
      context.setValue('addressLine1', addr.addressLine1)
    }
  }, [addr])
  return
}
