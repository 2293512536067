import React, { useCallback } from 'react'
import { ActionType } from '../actions'
import { Action, CartItem, Address, Purchase, Estimate, LeaveData, User } from '@hitlabel.jp/main-site/types'
import {
  requestUserData,
  uploadOrderData,
  requestDestinations,
  addDestination,
  updateDestination,
  deleteDestination,
  requestSources,
  addSource,
  updateSource,
  deleteSource,
  purchaseCancel,
  changeShipment,
  issueInvoice,
  issueReceipt,
  sendEstimateRequest,
  userLeaveRequest,
  userAccountUpdate,
  calcprice,
  downloadEstimatePdf,
} from '@hitlabel.jp/main-site/services'
import toast from 'react-hot-toast'

export const methods = (dispatch: React.Dispatch<Action>) => {
  const userActiveOrdersRequest = useCallback((orderId: number) => {
    dispatch({ type: ActionType.USER_ACTIVE_ORDERS_REQUEST })
    dispatch({ type: ActionType.USER_ACTIVE_ORDERS_RECEIVE })
  }, [])

  const userPurchaseRequest = useCallback((purchaseId: number) => {
    dispatch({ type: ActionType.USER_PURCHASE_REQUEST })
    dispatch({ type: ActionType.USER_PURCHASE_RECEIVE })
  }, [])

  const userPurchaseCancel = useCallback((id: number) => {
    dispatch({ type: ActionType.USER_PURCHASE_REQUEST_CANCEL })
    purchaseCancel(id).then(res => {
      if (res) {
        dispatch({ type: ActionType.USER_PURCHASE_DONE_CANCEL })
      }
    })
  }, [])
  const userPurchaseRequestInvoice = useCallback((id: number, name: string) => {
    dispatch({ type: ActionType.USER_PURCHASE_REQUEST_INVOICE })
    issueInvoice(name, id).then(res => {
      if (res) {
        dispatch({ type: ActionType.USER_PURCHASE_RECEIVE_INVOICE })
      }
    })
  }, [])
  const userPurchaseRequestReceipt = useCallback((id: number, name: string) => {
    dispatch({ type: ActionType.USER_PURCHASE_REQUEST_RECEIPT })
    issueReceipt(name, id).then(res => {
      if (res) {
        dispatch({ type: ActionType.USER_PURCHASE_RECEIVE_RECEIPT })
      }
    })
  }, [])
  const userPurchaseRequestHistories = useCallback((purchaseId: number) => {
    dispatch({ type: ActionType.USER_PURCHASE_REQUEST_HISTORIES })
    dispatch({ type: ActionType.USER_PURCHASE_RECEIVE_HISTORIES })
  }, [])
  const userPurchaseChangeShipment = useCallback(
    (purchaseId: number, destId: number, srcId: number, deliveryTime: string) => {
      dispatch({ type: ActionType.USER_PURCHASE_CHANGE_SHIPMENT_REQUEST })
      changeShipment(purchaseId, destId, srcId, deliveryTime).then(res => {
        dispatch({ type: ActionType.USER_PURCHASE_CHANGE_SHIPMENT_DONE })
      })
    },
    [],
  )
  const userAccountRequestUpdate = useCallback((user: User) => {
    dispatch({ type: ActionType.USER_ACCOUNT_REQUEST_UPDATE })
    userAccountUpdate(user).then(res => {
      if (res) {
        dispatch({ type: ActionType.USER_ACCOUNT_DONE_UPDATE })
      } else {
        dispatch({ type: ActionType.USER_ACCOUNT_FAIL_UPDATE })
      }
    })
  }, [])
  const userAccountRequestLeave = useCallback((purchaseId: number) => {
    dispatch({ type: ActionType.USER_ACCOUNT_REQUEST_LEAVE })
    dispatch({ type: ActionType.USER_ACCOUNT_DONE_LEAVE })
  }, [])
  // TODO: この辺のAPI処理を成功と失敗で分ける

  /**
   * 配送情報
   */
  const userShipmentSetAddressFormDefaultValues = useCallback((values: Address) => {
    dispatch({ type: ActionType.USER_SHIPMENT_SET_ADDRESS_FORM_DEFAULT_VALUES, payload: values })
  }, [])
  const userShipmentInitAddressFormDefaultValues = useCallback(() => {
    dispatch({ type: ActionType.USER_SHIPMENT_INIT_ADDRESS_FORM_DEFAULT_VALUES })
  }, [])
  const userShipmentRequestDestinations = useCallback(() => {
    dispatch({ type: ActionType.USER_SHIPMENT_REQUEST_DESTINATIONS })
    requestDestinations()
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_RECEIVE_DESTINATIONS, payload: res })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  const userShipmentAddDestination = useCallback((address: Address) => {
    dispatch({ type: ActionType.USER_SHIPMENT_ADD_DESTINATION })
    addDestination(address)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_ADD_DESTINATION_DONE })
          userShipmentRequestDestinations()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  const userShipmentDeleteDestination = useCallback((addressId: number) => {
    dispatch({ type: ActionType.USER_SHIPMENT_DELETE_DESTINATION })
    deleteDestination(addressId)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_DELETE_DESTINATION_DONE })
          userShipmentRequestDestinations()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  const userShipmentUpdateDestination = useCallback((address: Address) => {
    dispatch({ type: ActionType.USER_SHIPMENT_UPDATE_DESTINATION })
    updateDestination(address)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_UPDATE_DESTINATION })
          userShipmentRequestDestinations()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])
  const userShipmentRequestSources = useCallback(() => {
    dispatch({ type: ActionType.USER_SHIPMENT_REQUEST_SOURCES })
    requestSources()
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_RECEIVE_SOURCES, payload: res })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const userShipmentAddSource = useCallback((address: Address) => {
    dispatch({ type: ActionType.USER_SHIPMENT_ADD_SOURCE })
    addSource(address)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_ADD_SOURCE_DONE })
          userShipmentRequestSources()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const userShipmentDeleteSource = useCallback((addressId: number) => {
    dispatch({ type: ActionType.USER_SHIPMENT_DELETE_SOURCE })
    deleteSource(addressId)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_DELETE_SOURCE_DONE })
          userShipmentRequestSources()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const userShipmentUpdateSource = useCallback((address: Address) => {
    dispatch({ type: ActionType.USER_SHIPMENT_UPDATE_SOURCE })
    updateSource(address)
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_SHIPMENT_UPDATE_SOURCE_DONE })
          userShipmentRequestSources()
          userShipmentInitAddressFormDefaultValues()
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [])

  const userUploadFormOpen = useCallback((orderId: number, orderName: string) => {
    dispatch({ type: ActionType.USER_UPLOAD_FORM_OPEN, payload: { orderId, orderName } })
  }, [])

  const userUploadFormClose = useCallback(() => {
    dispatch({ type: ActionType.USER_UPLOAD_FORM_CLOSE })
  }, [])

  const userCancelFormOpen = useCallback((id: number, purchaseId: number, cartItems: CartItem[]) => {
    dispatch({ type: ActionType.USER_CANCEL_FORM_OPEN, payload: { id, purchaseId, cartItems } })
  }, [])

  const userCancelFormClose = useCallback(() => {
    dispatch({ type: ActionType.USER_CANCEL_FORM_CLOSE })
  }, [])

  const userChangeShipmentFormOpen = useCallback((purchase: Purchase) => {
    dispatch({ type: ActionType.USER_CHANGE_SHIPMENT_FORM_OPEN, payload: purchase })
  }, [])

  const userChangeShipmentFormClose = useCallback(() => {
    dispatch({ type: ActionType.USER_CHANGE_SHIPMENT_FORM_CLOSE })
  }, [])

  const userInvoiceFormOpen = useCallback((purchase: Purchase) => {
    dispatch({ type: ActionType.USER_INVOICE_FORM_OPEN, payload: purchase })
  }, [])

  const userInvoiceFormClose = useCallback(() => {
    dispatch({ type: ActionType.USER_INVOICE_FORM_CLOSE })
  }, [])

  const userReceiptFormOpen = useCallback((purchase: Purchase) => {
    dispatch({ type: ActionType.USER_RECEIPT_FORM_OPEN, payload: purchase })
  }, [])

  const userReceiptFormClose = useCallback(() => {
    dispatch({ type: ActionType.USER_RECEIPT_FORM_CLOSE })
  }, [])

  /**
   * 過去の注文をカートに入れる
   * 1. 注文情報をもとに価格を再計算
   * 2. 計算成功したらカートに追加する
   */
  const userOrderRepeat = useCallback((item: CartItem) => {
    calcprice(item).then(res => {
      if (res.data === null) {
        toast('ご指定の条件ではご注文いただけません', { icon: '⚠' })
      } else {
        dispatch({ type: ActionType.USER_ORDER_REPEAT, payload: { item: item, calcResult: res.data } })
        dispatch({ type: ActionType.GA4_ADD_TO_CART, payload: res.data })
      }
    })
  }, [])

  const userEstimateToCart = useCallback((estimate: Estimate, childNumber: number) => {
    dispatch({ type: ActionType.USER_ESTIMATE_TO_CART, payload: { estimate: estimate, childNumber: childNumber } })
  }, [])

  const userDownloadEstimatePdf = useCallback((estimate: Estimate) => {
    const fileName = `【御見積書】ヒット・ラベル_${estimate.estimateNumber}.pdf`
    downloadEstimatePdf(estimate.estimateNumber, fileName)
  }, [])

  const userLeave = useCallback((data: LeaveData) => {
    userLeaveRequest(data).then(res => {
      if (res) {
        dispatch({ type: ActionType.USER_LEAVE_DONE })
      }
    })
  }, [])

  return {
    userActiveOrdersRequest,
    userPurchaseRequest,
    userPurchaseCancel,
    userPurchaseRequestInvoice,
    userPurchaseRequestReceipt,
    userPurchaseRequestHistories,
    userPurchaseChangeShipment,
    userAccountRequestUpdate,
    userAccountRequestLeave,
    userShipmentInitAddressFormDefaultValues,
    userShipmentSetAddressFormDefaultValues,
    userShipmentRequestDestinations,
    userShipmentAddDestination,
    userShipmentDeleteDestination,
    userShipmentUpdateDestination,
    userShipmentRequestSources,
    userShipmentAddSource,
    userShipmentDeleteSource,
    userShipmentUpdateSource,
    userUploadFormOpen,
    userUploadFormClose,
    userCancelFormOpen,
    userCancelFormClose,
    userChangeShipmentFormOpen,
    userChangeShipmentFormClose,
    userInvoiceFormOpen,
    userInvoiceFormClose,
    userReceiptFormOpen,
    userReceiptFormClose,
    userOrderRepeat,
    userEstimateToCart,
    userDownloadEstimatePdf,
    userLeave,
  }
}
