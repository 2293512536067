import { dayjs } from './dayjs'
import { Purchase } from '@hitlabel.jp/main-site/types'
export * from './readable'
export * from './validate'
export { dayjs }
export const disableEnterKey = (e: any) => {
  if (e.keyCode === 13) e.preventDefault()
}

export const imageByName = (name: string, images: any[]) => {
  const image: any = images.filter((image: any) => {
    return image.name === name
  })[0]
  return image.childImageSharp.gatsbyImageData
}

export const optionByName = (
  options: { productId: number; name: string; displayName: string; disable: true; additionalDay?: number }[],
  name: string,
) => {
  return options.filter(option => {
    return option.name === name
  })[0]
}

export const postalCodeString = (code: number | string, icon?: boolean) => {
  const p = ('0000000' + code).slice(-7)
  let result = `${p.slice(0, 3)}-${p.slice(3, 7)}`
  if (icon) {
    result = `〒${result}`
  }
  return result
}

/**
 * 祝日かどうか
 */
export const isHoliday = (day: dayjs.Dayjs, holidays: any): boolean => {
  if (day.day() === 0 || day.day() === 6) {
    return true
  }
  for (const h of holidays) {
    const holiday = dayjs(h.day)
    if (day.isSame(holiday, 'day')) {
      return true
    }
  }
  return false
}

/**
 * 15時を超えたかどうか
 */
export const isAfter15 = () => {
  if (
    dayjs()
      .tz()
      .hour() >= 15
  ) {
    return true
  }
  return false
}

export const randomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max + 1 - min) + min)
}

/**
 * 支払いデータを新しい順にソートする
 */
export const sortPurchasesByNewest = (purchases: Purchase[]) => {
  if (!purchases) {
    return purchases
  }
  return purchases.sort((a, b) => {
    return b.createdAt - a.createdAt
  })
}

/**
 * 代引き手数料を返す
 * TODO: フロントエンドで計算するのをやめる
 */
export const getCommission = (amount: number) => {
  if (amount <= 9999) {
    return 330
  } else if (amount <= 29999) {
    return 440
  } else if (amount <= 99999) {
    return 660
  }
  return 0
}
