import { http } from './init'
import { CartItem } from '@hitlabel.jp/main-site/types'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

export const calcprice = async (values: CartItem) => {
  const v = { ...values }
  // Objectによってsnakecaseとcamecaseが入り混じっていることによる混沌...
  if (v.estimate) {
    v.estimate = snakecaseKeys(v.estimate, { deep: true }) as any
  }
  if (v.estimateData) {
    try {
      v.estimateData = snakecaseKeys(v.estimateData as any, { deep: true }) as any
      v.estimateData = JSON.stringify(v.estimateData) as any
    } catch (e) {}
  }
  return await http({
    method: 'post',
    url: '/v2/calcprice',
    data: JSON.stringify(v),
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => {
      if (res.status === 200) {
        return camelcaseKeys(res.data, { deep: true })
      }
      return null
    })
    .catch(err => {
      console.error(err)
      return false
    })
}
