import React, { useCallback } from 'react'
import { ActionType } from '../actions'
import type { Action, ContactForm, MaterialYaml } from '@hitlabel.jp/main-site/types'
import { sendContact } from '@hitlabel.jp/main-site/services'

export const methods = (dispatch: React.Dispatch<Action>) => {
  const contactFormSubmit = useCallback((data: ContactForm) => {
    dispatch({ type: ActionType.CONTACT_SUBMIT, payload: data })
  }, [])
  const contactFormSend = useCallback((data: ContactForm) => {
    dispatch({ type: ActionType.CONTACT_SEND_REQUEST })
    sendContact(data).then((res: any) => {
      if (res.status === 200) {
        dispatch({ type: ActionType.CONTACT_SEND_REQUEST_SUCCEED })
      } else {
        dispatch({ type: ActionType.CONTACT_SEND_REQUEST_FAIL })
      }
    })
  }, [])
  const loadMaterials = useCallback((data: MaterialYaml[]) => {
    dispatch({ type: ActionType.LOAD_MATERIALS, payload: data })
  }, [])
  const clearCart = useCallback(() => {
    dispatch({ type: ActionType.CLEAR_CART })
  }, [])
  return {
    contactFormSend,
    contactFormSubmit,
    loadMaterials,
  }
}
