import { ActionType } from '../actions'
import { HitlabelState, Action } from '@hitlabel.jp/main-site/types'
import { navigate } from 'gatsby'
import { cardTokenReceive, cardTokenError } from './epsilon'
import { locationChanged, receiveHolidays, receiveShippingDates, productsReceive, loadMaterials } from './general'
import toast from 'react-hot-toast'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'
import {
  authSignupConfirm,
  authSignupRequest,
  authSignupReceive,
  authResetUrlRequest,
  authResetUrlRequestFail,
  authResetUrlRequestSucceed,
  authResetTokenValidate,
  authResetTokenValidateRequestFail,
  authResetTokenValidateRequestSucceed,
  authResetRequest,
  authResetRequestSucceed,
} from './auth'
import {
  checkoutStart,
  checkoutChangePaymentMethod,
  checkoutRequestSucceed,
  checkoutRequestFail,
  checkoutChangeCoupon,
  checkoutValidateCouponDone,
  checkoutValidateCouponFail,
  checkoutChangePoint,
  checkoutChangeShipmentSource,
  checkoutChangeShipmentDestination,
  checkoutChangeShipmentDeliveryTime,
  checkoutChangeNote,
  consentChangeExampleUse,
  priorConsentChange,
} from './checkout'
import {
  cartLoadFromLocalStorage,
  cartClear,
  cartItemDuplicate,
  cartItemDelete,
  cartItemChangeName,
  cartItemChangeNum,
  cartItemChangeX,
  cartItemChangeY,
  cartItemSetErrorSize,
  cartItemClearErrorSize,
  cartItemReceiveSize,
  cartItemReceiveShape,
  cartItemChangeShippingDate,
  cartItemCalcPrice,
  cartCalcTotalPrice,
} from './cart'
import {
  currentItemInit,
  currentItemReset,
  currentItemChangeX,
  currentItemChangeY,
  currentItemChangeSize,
  currentItemChangeName,
  currentItemReceiveSize,
  currentItemReceiveShape,
  currentItemReceiveNums,
  currentItemRequestPrice,
  currentItemReceivePrice,
  currentItemChangeNum,
  currentItemChangeDeliveryDateProduct,
  currentItemChangeOption,
  currentItemAddToCart,
  currentItemSetProgress,
  currentItemSetErrorSize,
  currentItemSetDeliveryDateOption,
} from './current-item'
import {
  userAccountRequest,
  userAccountReceive,
  userShipmentRequestDestinations,
  userShipmentReceiveDestinations,
  userShipmentInitAddressFormDefaultValues,
  userShipmentSetAddressFormDefaultValues,
  userShipmentRequestSources,
  userShipmentReceiveSources,
  userShipmentAddDestination,
  userShipmentUpdateDestination,
  userShipmentDeleteDestination,
  userShipmentAddSource,
  userShipmentUpdateSource,
  userShipmentDeleteSource,
  userCurrentPurchaseReceive,
  userActiveOrdersReceive,
  userDeliveredOrdersReceive,
  userEstimatesReceive,
  userPurchasesReceive,
  userUploadFormOpen,
  userUploadFormClose,
  userCancelFormOpen,
  userCancelFormClose,
  userChangeShipmentFormOpen,
  userChangeShipmentFormClose,
  userInvoiceFormOpen,
  userInvoiceFormClose,
  userReceiptFormOpen,
  userReceiptFormClose,
  userPurchaseRequestCancel,
  userPurchaseDoneCancel,
  userPurchaseChangeShipmentRequest,
  userPurchaseChangeShipmentDone,
  userPurchaseRequestInvoice,
  userPurchaseReceiveInvoice,
  userPurchaseRequestReceipt,
  userPurchaseReceiveReceipt,
  userOrderRepeat,
  userEstimateToCart,
  userLeaveDone,
  userAccountRequestUpdate,
  userAccountDoneUpdate,
  userAccountFailUpdate,
} from './user'
import {
  contactInit,
  contactSubmit,
  contactSendRequest,
  contactSendRequestSucceed,
  contactSendRequestFail,
} from './contact'
import { ga4Event } from './ga4'

export const reducer = (state: HitlabelState, action: Action): HitlabelState => {
  // console.debug('ACTION:', action.type)
  switch (action.type) {
    case ActionType.TOGGLE_USER_MENU:
      if (state.auth.isAuthed) {
        return { ...state, userMenuOpen: !state.userMenuOpen }
      }
      return state
    case ActionType.TOGGLE_MENU:
      return { ...state, menuOpen: !state.menuOpen }
    case ActionType.SAMPLE_FORM_SUBMIT:
      return { ...state, sampleForm: action.payload }
    case ActionType.SAMPLE_FORM_REQUEST_SEND:
      return { ...state, requesting: true }
    case ActionType.SAMPLE_FORM_REQUEST_SUCCEED:
      navigate('/request-sample/done')
      return { ...state, requesting: false, requestError: '' }
    case ActionType.SAMPLE_FORM_REQUEST_FAIL:
      return { ...state, requesting: false, requestError: 'error' }
    case ActionType.ESTIMATE_FORM_SUBMIT:
      // 数値として保存
      action.payload.num0 = String(Number(action.payload.num0))
      action.payload.num1 = String(Number(action.payload.num1))
      action.payload.num2 = String(Number(action.payload.num2))
      return {
        ...state,
        estimateForm: {
          ...action.payload,
          organization: state.user.organization,
          name: `${state.user.familyName} ${state.user.givenName}`,
          kana: `${state.user.familyKana} ${state.user.givenKana}`,
          postalCode: state.user.postalCode,
          addressLevel1: state.user.addressLevel1,
          addressLevel2: state.user.addressLevel2,
          addressLine1: state.user.addressLine1,
          addressLine2: state.user.addressLine2,
          tel: state.user.tel,
          email: state.user.email,
        },
      }
    case ActionType.ESTIMATE_FORM_REQUEST_SEND:
      return { ...state, requesting: true }
    case ActionType.ESTIMATE_FORM_REQUEST_SUCCEED:
      navigate('/request-estimate/done')
      return { ...state, requesting: false, requestError: '' }
    case ActionType.ESTIMATE_FORM_REQUEST_FAIL:
      return { ...state, requesting: false, requestError: 'error' }
    case ActionType.AUTH_SIGNUP_CONFIRM:
      return authSignupConfirm(action.payload, state)
    case ActionType.AUTH_SIGNUP_REQUEST:
      return authSignupRequest(state)
    case ActionType.AUTH_SIGNUP_RECEIVE:
      return authSignupReceive(action.payload, state)
    case ActionType.AUTH_LOAD_TOKEN:
      return { ...state, auth: { ...state.auth, token: action.payload }, requestError: '' }
    case ActionType.AUTH_LOAD_TOKEN_FAIL:
      return { ...state, auth: { ...state.auth, token: '', isAuthed: false }, requestError: '' }
    case ActionType.AUTH_LOGIN_STARTED:
      return { ...state, auth: { ...state.auth, backTo: action.payload } }
    case ActionType.AUTH_LOGIN_FINISHED:
      return { ...state, auth: { ...state.auth, backTo: '' } }
    case ActionType.AUTH_LOGIN_REQUEST:
      return { ...state, requesting: true }
    case ActionType.AUTH_LOGIN_REQUEST_SUCCEED:
      toast.success('ログインに成功しました')
      localStorage.setItem(`${LS_PREFIX}:token`, action.payload)
      return {
        ...state,
        requesting: false,
        auth: { ...state.auth, token: action.payload, isAuthed: true },
        requestError: '',
      }
    case ActionType.AUTH_LOGIN_REQUEST_FAIL:
      return {
        ...state,
        requesting: false,
        requestError: 'error',
        auth: {
          ...state.auth,
          token: '',
          isAuthed: false,
        },
      }
    case ActionType.AUTH_LOGIN_CHECK_SUCCEED:
      return { ...state, auth: { ...state.auth, token: state.auth.token, isAuthed: true }, requestError: '' }
    case ActionType.AUTH_LOGIN_CHECK_FAIL:
      return { ...state, auth: { ...state.auth, token: '', isAuthed: false }, requestError: '' }
    case ActionType.AUTH_LOGOUT:
      navigate('/')
      localStorage.removeItem(`${LS_PREFIX}:token`)
      toast.success('ログアウトされました')
      return {
        ...state,
        userMenuOpen: false,
        user: {
          id: 0,
          email: '',
          organization: '',
          familyName: '',
          givenName: '',
          familyKana: '',
          givenKana: '',
          postalCode: '',
          addressLevel1: '',
          addressLevel2: '',
          addressLine1: '',
          addressLine2: '',
          tel: '',
          fax: '',
          emergencyTel: '',
          holdingPoint: 0,
          sources: [],
          destinations: [],
          activeOrders: [],
          deliveredOrders: [],
          estimateTo: '',
        },
        auth: { token: '', isAuthed: false, backTo: '', resetTokenValidated: null },
        requestError: '',
      }
    case ActionType.AUTH_RESET_URL_REQUEST:
      return authResetUrlRequest(state)
    case ActionType.AUTH_RESET_URL_REQUEST_SUCCEED:
      return authResetUrlRequestSucceed(state)
    case ActionType.AUTH_RESET_URL_REQUEST_FAIL:
      return authResetUrlRequestFail(action.payload, state)
    case ActionType.AUTH_RESET_TOKEN_VALIDATE:
      return authResetTokenValidate(state)
    case ActionType.AUTH_RESET_TOKEN_VALIDATE_REQUEST_SUCCEED:
      return authResetTokenValidateRequestSucceed(state)
    case ActionType.AUTH_RESET_TOKEN_VALIDATE_REQUEST_FAIL:
      return authResetTokenValidateRequestFail(state)
    case ActionType.AUTH_RESET_REQUEST:
      return authResetRequest(state)
    case ActionType.AUTH_RESET_REQUEST_SUCCEED:
      return authResetRequestSucceed(state)
    case ActionType.USER_ACCOUNT_RECEIVE:
      return {
        ...state,
        user: action.payload,
        estimateForm: {
          ...state.estimateForm,
          deliveryTo: action.payload.addressLevel1,
        },
        sampleForm: {
          ...state.sampleForm,
          organization: action.payload.organization,
          name: `${action.payload.familyName} ${action.payload.givenName}`,
          kana: `${action.payload.familyKana} ${action.payload.givenKana}`,
          postalCode: action.payload.postalCode,
          addressLevel1: action.payload.addressLevel1,
          addressLevel2: action.payload.addressLevel2,
          addressLine1: action.payload.addressLine1,
          addressLine2: action.payload.addressLine2,
          tel: action.payload.tel,
          email: action.payload.email,
        },
      }
    case ActionType.USER_ACCOUNT_REQUEST_UPDATE:
      return userAccountRequestUpdate(state)
    case ActionType.USER_ACCOUNT_DONE_UPDATE:
      return userAccountDoneUpdate(state)
    case ActionType.USER_ACCOUNT_FAIL_UPDATE:
      return userAccountFailUpdate(state)
    case ActionType.LOCATION_CHANGED:
      return locationChanged(action.payload, state)
    case ActionType.CARD_TOKEN_REQUEST:
      return { ...state, requesting: true }
    case ActionType.CARD_TOKEN_RECEIVE:
      return cardTokenReceive(action.payload, state)
    case ActionType.CARD_TOKEN_ERROR:
      return cardTokenError(action.payload, state)
    case ActionType.CHECKOUT_START:
      return checkoutStart(state)
    case ActionType.CHECKOUT_REQUEST_SEND:
      return { ...state, requesting: true }
    case ActionType.CHECKOUT_REQUEST_SUCCEED:
      return checkoutRequestSucceed(action.payload, state)
    case ActionType.CHECKOUT_REQUEST_FAIL:
      return checkoutRequestFail(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_COUPON:
      return checkoutChangeCoupon(action.payload, state)
    case ActionType.CHECKOUT_VALIDATE_COUPON:
      return { ...state }
    case ActionType.CHECKOUT_VALIDATE_COUPON_DONE:
      return checkoutValidateCouponDone(action.payload, state)
    case ActionType.CHECKOUT_VALIDATE_COUPON_FAIL:
      return checkoutValidateCouponFail(state)
    case ActionType.CHECKOUT_CHANGE_POINT:
      return checkoutChangePoint(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_PAYMENT_METHOD:
      return checkoutChangePaymentMethod(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_SHIPMENT_SOURCE:
      return checkoutChangeShipmentSource(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_SHIPMENT_DESTINATION:
      return checkoutChangeShipmentDestination(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_SHIPMENT_DELIVERY_TIME:
      return checkoutChangeShipmentDeliveryTime(action.payload, state)
    case ActionType.CHECKOUT_CHANGE_NOTE:
      return checkoutChangeNote(action.payload, state)
    case ActionType.CONSENT_CHANGE_EXAMPLE_USE:
      return consentChangeExampleUse(action.payload, state)
    case ActionType.PRIOR_CONSENT_CHANGE:
      return priorConsentChange(action.payload, state)
    case ActionType.CURRENT_ITEM_INIT:
      return currentItemInit(state)
    case ActionType.CURRENT_ITEM_RESET:
      return currentItemReset(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_X:
      return currentItemChangeX(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_Y:
      return currentItemChangeY(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_SIZE:
      return currentItemChangeSize(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_NAME:
      return currentItemChangeName(action.payload, state)
    case ActionType.CURRENT_ITEM_RECEIVE_SIZE:
      return currentItemReceiveSize(action.payload, state)
    case ActionType.CURRENT_ITEM_RECEIVE_SHAPE:
      return currentItemReceiveShape(action.payload, state)
    case ActionType.CURRENT_ITEM_RECEIVE_NUMS:
      return currentItemReceiveNums(action.payload, state)
    case ActionType.CURRENT_ITEM_REQUEST_PRICE:
      return currentItemRequestPrice(state)
    case ActionType.CURRENT_ITEM_RECEIVE_PRICE:
      return currentItemReceivePrice(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_ADHESIVE:
      return currentItemChangeOption(action.payload, state, 'adhesive')
    case ActionType.CURRENT_ITEM_CHANGE_WHITE_INK:
      return currentItemChangeOption(action.payload, state, 'whiteInk')
    case ActionType.CURRENT_ITEM_CHANGE_WHITEDATA_SERVICE:
      return currentItemChangeOption(action.payload, state, 'whitedataService')
    case ActionType.CURRENT_ITEM_CHANGE_LAMINATION:
      return currentItemChangeOption(action.payload, state, 'lamination')
    case ActionType.CURRENT_ITEM_CHANGE_HALFCUT:
      return currentItemChangeOption(action.payload, state, 'halfcut')
    case ActionType.CURRENT_ITEM_CHANGE_FORMAT:
      return currentItemChangeOption(action.payload, state, 'format')
    case ActionType.CURRENT_ITEM_CHANGE_CUTLINE_SERVICE:
      return currentItemChangeOption(action.payload, state, 'cutlineService')
    case ActionType.CURRENT_ITEM_CHANGE_CUTPATH:
      return currentItemChangeOption(action.payload, state, 'cutpath')
    case ActionType.CURRENT_ITEM_CHANGE_SLIT:
      return currentItemChangeOption(action.payload, state, 'slit')
    case ActionType.CURRENT_ITEM_CHANGE_BACKSIDE:
      return currentItemChangeOption(action.payload, state, 'backSide')
    case ActionType.CURRENT_ITEM_CHANGE_ONDEMAND_FOIL:
      return currentItemChangeOption(action.payload, state, 'ondemandFoil')
    case ActionType.CURRENT_ITEM_CHANGE_DELIVERY_FORM:
      return currentItemChangeOption(action.payload, state, 'deliveryForm')
    case ActionType.CURRENT_ITEM_CHANGE_SEND_COPY:
      return currentItemChangeOption(action.payload, state, 'sendCopy')
    case ActionType.CURRENT_ITEM_CHANGE_DELIVERY_DATE_PRODUCT:
      return currentItemChangeDeliveryDateProduct(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_NUM:
      return currentItemChangeNum(action.payload, state)
    case ActionType.CURRENT_ITEM_CHANGE_INDIVIDUAL_PACKAGING:
      return currentItemChangeOption(action.payload, state, 'individualPackaging')
    case ActionType.CURRENT_ITEM_SET_PROGRESS:
      return currentItemSetProgress(action.payload, state)
    case ActionType.CURRENT_ITEM_ADD_TO_CART:
      return currentItemAddToCart(action.payload, state)
    case ActionType.CURRENT_ITEM_SET_ERROR_SIZE:
      return currentItemSetErrorSize(action.payload, state)
    case ActionType.CURRENT_ITEM_SET_DELIVERY_DATE_OPTION:
      return currentItemSetDeliveryDateOption(state)
    case ActionType.CART_LOAD_FROM_LOCAL_STORAGE:
      return cartLoadFromLocalStorage(action.payload, state)
    case ActionType.CART_CLEAR:
      return cartClear(state)
    case ActionType.CART_CALC_TOTAL_PRICE:
      return cartCalcTotalPrice(state)
    case ActionType.CART_ITEM_DUPLICATE:
      return cartItemDuplicate(action.payload, state)
    case ActionType.CART_ITEM_CHANGE_NAME:
      return cartItemChangeName(action.payload, state)
    case ActionType.CART_ITEM_CHANGE_SHIPPING_DATE:
      return cartItemChangeShippingDate(action.payload, state)
    case ActionType.CART_ITEM_CHANGE_NUM:
      return cartItemChangeNum(action.payload, state)
    case ActionType.CART_ITEM_CHANGE_X:
      return cartItemChangeX(action.payload, state)
    case ActionType.CART_ITEM_CHANGE_Y:
      return cartItemChangeY(action.payload, state)
    case ActionType.CART_ITEM_SET_ERROR_SIZE:
      return cartItemSetErrorSize(action.payload, state)
    case ActionType.CART_ITEM_CLEAR_ERROR_SIZE:
      return cartItemClearErrorSize(action.payload, state)
    case ActionType.CART_ITEM_RECEIVE_SIZE:
      return cartItemReceiveSize(action.payload, state)
    case ActionType.CART_ITEM_RECEIVE_SHAPE:
      return cartItemReceiveShape(action.payload, state)
    case ActionType.CART_ITEM_DELETE:
      return cartItemDelete(action.payload, state)
    case ActionType.CART_ITEM_CALC_PRICE:
      return cartItemCalcPrice(action.payload, state)
    case ActionType.USER_ACCOUNT_REQUEST:
      return userAccountRequest(state)
    case ActionType.USER_SHIPMENT_SET_ADDRESS_FORM_DEFAULT_VALUES:
      return userShipmentSetAddressFormDefaultValues(action.payload, state)
    case ActionType.USER_SHIPMENT_INIT_ADDRESS_FORM_DEFAULT_VALUES:
      return userShipmentInitAddressFormDefaultValues(state)
    case ActionType.USER_SHIPMENT_REQUEST_DESTINATIONS:
      return userShipmentRequestDestinations(state)
    case ActionType.USER_SHIPMENT_RECEIVE_DESTINATIONS:
      return userShipmentReceiveDestinations(action.payload, state)
    case ActionType.USER_SHIPMENT_REQUEST_SOURCES:
      return userShipmentRequestSources(state)
    case ActionType.USER_SHIPMENT_RECEIVE_SOURCES:
      return userShipmentReceiveSources(action.payload, state)
    case ActionType.USER_SHIPMENT_ADD_DESTINATION:
      return userShipmentAddDestination(state)
    case ActionType.USER_SHIPMENT_UPDATE_DESTINATION:
      return userShipmentUpdateDestination(action.payload, state)
    case ActionType.USER_SHIPMENT_DELETE_DESTINATION:
      return userShipmentDeleteDestination(action.payload, state)
    case ActionType.USER_SHIPMENT_ADD_SOURCE:
      return userShipmentAddSource(state)
    case ActionType.USER_SHIPMENT_UPDATE_SOURCE:
      return userShipmentUpdateSource(action.payload, state)
    case ActionType.USER_SHIPMENT_DELETE_SOURCE:
      return userShipmentDeleteSource(action.payload, state)
    case ActionType.USER_CURRENT_PURCHASE_RECEIVE:
      return userCurrentPurchaseReceive(action.payload, state)
    case ActionType.USER_ACTIVE_ORDERS_REQUEST:
      return state
    case ActionType.USER_ACTIVE_ORDERS_RECEIVE:
      return userActiveOrdersReceive(action.payload, state)
    case ActionType.USER_DELIVERED_ORDERS_REQUEST:
      return state
    case ActionType.USER_DELIVERED_ORDERS_RECEIVE:
      return userDeliveredOrdersReceive(action.payload, state)
    case ActionType.USER_ESTIMATES_REQUEST:
      return state
    case ActionType.USER_ESTIMATES_RECEIVE:
      return userEstimatesReceive(action.payload, state)
    case ActionType.USER_PURCHASES_REQUEST:
      return state
    case ActionType.USER_PURCHASES_RECEIVE:
      return userPurchasesReceive(action.payload, state)
    case ActionType.USER_PURCHASE_REQUEST_CANCEL:
      return userPurchaseRequestCancel(state)
    case ActionType.USER_PURCHASE_DONE_CANCEL:
      return userPurchaseDoneCancel(state)
    case ActionType.USER_UPLOAD_FORM_OPEN:
      return userUploadFormOpen(action.payload, state)
    case ActionType.USER_UPLOAD_FORM_CLOSE:
      return userUploadFormClose(state)
    case ActionType.USER_CANCEL_FORM_OPEN:
      return userCancelFormOpen(action.payload, state)
    case ActionType.USER_CANCEL_FORM_CLOSE:
      return userCancelFormClose(state)
    case ActionType.USER_CHANGE_SHIPMENT_FORM_OPEN:
      return userChangeShipmentFormOpen(action.payload, state)
    case ActionType.USER_CHANGE_SHIPMENT_FORM_CLOSE:
      return userChangeShipmentFormClose(state)
    case ActionType.USER_INVOICE_FORM_OPEN:
      return userInvoiceFormOpen(action.payload, state)
    case ActionType.USER_INVOICE_FORM_CLOSE:
      return userInvoiceFormClose(state)
    case ActionType.USER_RECEIPT_FORM_OPEN:
      return userReceiptFormOpen(action.payload, state)
    case ActionType.USER_RECEIPT_FORM_CLOSE:
      return userReceiptFormClose(state)
    case ActionType.USER_PURCHASE_CHANGE_SHIPMENT_REQUEST:
      return userPurchaseChangeShipmentRequest(state)
    case ActionType.USER_PURCHASE_CHANGE_SHIPMENT_DONE:
      return userPurchaseChangeShipmentDone(state)
    case ActionType.USER_PURCHASE_REQUEST_INVOICE:
      return userPurchaseRequestInvoice(state)
    case ActionType.USER_PURCHASE_RECEIVE_INVOICE:
      return userPurchaseReceiveInvoice(state)
    case ActionType.USER_PURCHASE_REQUEST_RECEIPT:
      return userPurchaseRequestReceipt(state)
    case ActionType.USER_PURCHASE_RECEIVE_RECEIPT:
      return userPurchaseReceiveReceipt(state)
    case ActionType.RECEIVE_SHIPPING_DATES:
      return receiveShippingDates(action.payload, state)
    case ActionType.RECEIVE_HOLIDAYS:
      return receiveHolidays(action.payload, state)
    case ActionType.USER_ORDER_REPEAT:
      return userOrderRepeat(action.payload, state)
    case ActionType.USER_ESTIMATE_TO_CART:
      return userEstimateToCart(action.payload, state)
    case ActionType.USER_LEAVE_DONE:
      return userLeaveDone(state)
    case ActionType.CONTACT_INIT:
      return contactInit(state)
    case ActionType.CONTACT_SUBMIT:
      return contactSubmit(action.payload, state)
    case ActionType.CONTACT_SEND_REQUEST:
      return contactSendRequest(state)
    case ActionType.CONTACT_SEND_REQUEST_SUCCEED:
      return contactSendRequestSucceed(state)
    case ActionType.CONTACT_SEND_REQUEST_FAIL:
      return contactSendRequestFail(state)
    case ActionType.PRODUCTS_RECEIVE:
      return productsReceive(action.payload, state)
    case ActionType.LOAD_MATERIALS:
      return loadMaterials(action.payload, state)
    case ActionType.GA4_LOGIN:
      return ga4Event(action, state)
    case ActionType.GA4_SIGN_UP:
      return ga4Event(action, state)
    case ActionType.GA4_SEARCH:
      return ga4Event(action, state)
    case ActionType.GA4_ADD_TO_CART:
      return ga4Event(action, state)
    case ActionType.GA4_REMOVE_FROM_CART:
      return ga4Event(action, state)
    case ActionType.GA4_ADD_PAYMENT_INFO:
      return ga4Event(action, state)
    case ActionType.GA4_ADD_SHIPPING_INFO:
      return ga4Event(action, state)
    case ActionType.GA4_BEGIN_CHECKOUT:
      return ga4Event(action, state)
    case ActionType.GA4_PURCHASE:
      return ga4Event(action, state)
    case ActionType.GA4_REFUND:
      return ga4Event(action, state)
    case ActionType.GA4_GENERATE_LEAD:
      return ga4Event(action, state)
    case ActionType.GA4_SELECT_ITEM:
      return ga4Event(action, state)
    case ActionType.GA4_VIEW_CART:
      return ga4Event(action, state)
    case ActionType.GA4_VIEW_ITEM:
      return ga4Event(action, state)
    case ActionType.GA4_VIEW_ITEM_LIST:
      return ga4Event(action, state)
    case ActionType.GA4_VIEW_PROMOTION:
      return ga4Event(action, state)
    default:
      console.error('INVALID ACTION_TYPE', action.type)
  }
  return state
}
