import React from 'react'
import { reducer } from '@hitlabel.jp/main-site/hooks/hitlabel/reducers'
import {
  loginCheckRequest,
  loginRequest,
  sendEstimateRequest,
  sendSampleRequest,
  sendPurchaseRequest,
  requestInformations,
} from '@hitlabel.jp/main-site/services'
import { ActionType } from './actions'
import { initialState } from './initial-state'
import { useEpsilon } from './checkout'
import type { CreditCard, CartItem, HitlabelState } from '@hitlabel.jp/main-site/types'
import { methods as generalMethods, effects as generalEffects } from './general'
import { methods as authMethods, effects as authEffects } from './auth'
import { methods as cartMethods, effects as cartEffects } from './cart'
import { methods as currentItemMethods, effects as currentItemEffects } from './current-item'
import { methods as userMethods, effects as userEffects } from './user'
import { methods as checkoutMethods, effects as checkoutEffects } from './checkout'
import { LS_PREFIX } from '@hitlabel.jp/main-site/const'

const debugState = (state: HitlabelState) => {
  const j = JSON.stringify(state)
  const s = JSON.parse(j)
  console.debug('STATE :', s)
}

export const useHitlabel = () => {
  const [state, dispatch] = React.useReducer(reducer, initialState())
  const [informations, setInformations] = React.useState([])
  const { getToken, token, maskedCardNo, errorMessage } = useEpsilon()

  if (process.env.GATSBY_ENVIRONMENT !== 'production' && state) {
    debugState(state)
  }

  /**
   * 初期化
   **/
  React.useEffect(() => {
    const token = localStorage.getItem(`${LS_PREFIX}:token`)
    if (token) {
      dispatch({ type: ActionType.AUTH_LOAD_TOKEN, payload: token })
    } else {
      dispatch({ type: ActionType.AUTH_LOAD_TOKEN_FAIL })
    }
    const cart = localStorage.getItem(`${LS_PREFIX}:cart`)
    if (cart) {
      dispatch({ type: ActionType.CART_LOAD_FROM_LOCAL_STORAGE, payload: cart })
    }
    const f = async () => {
      const informations = await requestInformations()
      if (informations) {
        setInformations(informations)
      }
    }
    f()
  }, [])

  React.useEffect(() => {
    if (state.auth.token) {
      checkLogin()
    }
  }, [state.auth.token])

  /**
   * トークン決済用のトークンを受信してStateに保存
   **/
  React.useEffect(() => {
    if (token) {
      dispatch({ type: ActionType.CARD_TOKEN_RECEIVE, payload: { token, maskedCardNo } })
    }
  }, [token])

  /**
   * トークン決済用のエラーメッセージがあればStateに保存
   **/
  React.useEffect(() => {
    if (errorMessage) {
      dispatch({ type: ActionType.CARD_TOKEN_ERROR, payload: errorMessage })
    }
  }, [errorMessage])

  /**
   * Stateのカードトークンが変更になったら決済処処を進める
   **/
  React.useEffect(() => {
    if (state.checkout.card.token) {
      const checkout = state.checkout
      checkout.items = state.cart.items
      sendPurchaseRequest(checkout).then(result => {
        if (result.response && result.response.status !== 200) {
          dispatch({ type: ActionType.CHECKOUT_REQUEST_FAIL, payload: result.response.data.message })
        } else {
          dispatch({ type: ActionType.CHECKOUT_REQUEST_SUCCEED, payload: result })
        }
      })
      dispatch({ type: ActionType.CHECKOUT_REQUEST_SEND })
    }
  }, [state.checkout.card.token])

  generalEffects(state, dispatch)
  authEffects(state, dispatch)
  cartEffects(state, dispatch)
  currentItemEffects(state, dispatch)
  userEffects(state, dispatch)
  checkoutEffects(state, dispatch)

  /**
   * ログインリクエスト
   **/
  const login = React.useCallback(async (email: string, password: string) => {
    loginRequest(email, password).then(token => {
      if (token === '') {
        dispatch({ type: ActionType.AUTH_LOGIN_REQUEST_FAIL })
        return
      }
      dispatch({ type: ActionType.AUTH_LOGIN_REQUEST_SUCCEED, payload: token })
      dispatch({ type: ActionType.GA4_LOGIN })
    })
    dispatch({ type: ActionType.AUTH_LOGIN_REQUEST })
  }, [])

  /**
   * ログイン確認リクエスト
   */
  const checkLogin = React.useCallback(async () => {
    if (!state.auth.token) {
      return
    }
    loginCheckRequest(state.auth.token).then(result => {
      if (result) {
        dispatch({ type: ActionType.AUTH_LOGIN_CHECK_SUCCEED })
      } else {
        dispatch({ type: ActionType.AUTH_LOGIN_CHECK_FAIL })
      }
    })
  }, [state.auth.token])

  /**
   * サンプル請求フォーム送信
   **/
  const sendSample = React.useCallback(async () => {
    sendSampleRequest(state.sampleForm).then(result => {
      if (result) {
        dispatch({ type: ActionType.SAMPLE_FORM_REQUEST_SUCCEED })
      } else {
        dispatch({ type: ActionType.SAMPLE_FORM_REQUEST_FAIL })
      }
    })
    dispatch({ type: ActionType.SAMPLE_FORM_REQUEST_SEND })
  }, [state.sampleForm])

  /**
   * 見積もりフォーム送信
   **/
  const sendEstimate = React.useCallback(async () => {
    sendEstimateRequest(state.estimateForm).then(result => {
      if (result) {
        dispatch({ type: ActionType.ESTIMATE_FORM_REQUEST_SUCCEED })
      } else {
        dispatch({ type: ActionType.ESTIMATE_FORM_REQUEST_FAIL })
      }
    })
    dispatch({ type: ActionType.ESTIMATE_FORM_REQUEST_SEND })
  }, [state.estimateForm])

  /**
   * トークン決済用のリクエスト
   **/
  const requestTokenPurchase = React.useCallback(async (values: CreditCard) => {
    // エラーメッセージ消す
    dispatch({ type: ActionType.CARD_TOKEN_ERROR, payload: '' })
    dispatch({ type: ActionType.CARD_TOKEN_REQUEST })
    getToken(values)
  }, [])

  /**
   * 支払実行
   **/
  const requestPurchase = React.useCallback(async () => {
    const checkout = state.checkout
    checkout.items = state.cart.items
    sendPurchaseRequest(checkout).then(result => {
      if (result.response && result.response.status !== 200) {
        dispatch({ type: ActionType.CHECKOUT_REQUEST_FAIL, payload: result.response.data.message })
      } else {
        dispatch({ type: ActionType.CHECKOUT_REQUEST_SUCCEED, payload: result })
      }
    })
    dispatch({ type: ActionType.CHECKOUT_REQUEST_SEND })
  }, [JSON.stringify(state.checkout)])

  const { signupConfirm, signup, resetUrl, validateResetToken, reset } = authMethods(dispatch)

  const { contactFormSubmit, contactFormSend, loadMaterials } = generalMethods(dispatch)

  const {
    cartClear,
    cartItemDuplicate,
    cartItemDelete,
    cartItemChangeName,
    cartItemChangeShippingDate,
    cartItemChangeNum,
    cartItemChangeX,
    cartItemChangeY,
    cartRequestEstimatePdf,
  } = cartMethods(dispatch)

  const {
    currentItemReset,
    currentItemChangeY,
    currentItemChangeX,
    currentItemChangeSize,
    currentItemChangeName,
    currentItemChangeAdhesive,
    currentItemChangeLamination,
    currentItemChangeHalfcut,
    currentItemChangeFormat,
    currentItemChangeCutlineService,
    currentItemChangeCutpath,
    currentItemChangeWhiteInk,
    currentItemChangeWhitedataService,
    currentItemChangeSlit,
    currentItemChangeBackside,
    currentItemChangeOndemandFoil,
    currentItemChangeDeliveryForm,
    currentItemChangeSendCopy,
    currentItemChangeDeliveryDateProduct,
    currentItemChangeNum,
    currentItemChangeIndividualPackaging,
    currentItemAddToCart,
    currentItemRequestEstimatePdf,
  } = currentItemMethods(dispatch)

  const {
    checkoutChangePaymentMethod,
    checkoutChangeCoupon,
    checkoutChangePoint,
    checkoutChangeShipmentDestination,
    checkoutChangeShipmentSource,
    checkoutChangeShipmentDeliveryTime,
    checkoutChangeNote,
    consentChangeExampleUse,
    priorConsentChange,
  } = checkoutMethods(dispatch)

  const {
    userActiveOrdersRequest,
    userPurchaseRequest,
    userPurchaseCancel,
    userPurchaseRequestInvoice,
    userPurchaseRequestReceipt,
    userPurchaseRequestHistories,
    userPurchaseChangeShipment,
    userAccountRequestUpdate,
    userAccountRequestLeave,
    userShipmentSetAddressFormDefaultValues,
    userShipmentInitAddressFormDefaultValues,
    userShipmentRequestDestinations,
    userShipmentAddDestination,
    userShipmentDeleteDestination,
    userShipmentUpdateDestination,
    userShipmentRequestSources,
    userShipmentAddSource,
    userShipmentDeleteSource,
    userShipmentUpdateSource,
    userUploadFormOpen,
    userUploadFormClose,
    userCancelFormOpen,
    userCancelFormClose,
    userChangeShipmentFormOpen,
    userChangeShipmentFormClose,
    userInvoiceFormOpen,
    userInvoiceFormClose,
    userReceiptFormOpen,
    userReceiptFormClose,
    userOrderRepeat,
    userEstimateToCart,
    userDownloadEstimatePdf,
    userLeave,
  } = userMethods(dispatch)

  return {
    state,
    dispatch,
    informations,
    methods: {
      signupConfirm,
      signup,
      resetUrl,
      validateResetToken,
      reset,
      login,
      checkLogin,
      sendSample,
      sendEstimate,
      requestPurchase,
      requestTokenPurchase,
      cartClear,
      cartItemDuplicate,
      cartItemDelete,
      cartItemChangeName,
      cartItemChangeShippingDate,
      cartItemChangeNum,
      cartItemChangeX,
      cartItemChangeY,
      cartRequestEstimatePdf,
      currentItemReset,
      currentItemChangeY,
      currentItemChangeX,
      currentItemChangeSize,
      currentItemChangeName,
      currentItemChangeAdhesive,
      currentItemChangeLamination,
      currentItemChangeHalfcut,
      currentItemChangeFormat,
      currentItemChangeCutlineService,
      currentItemChangeCutpath,
      currentItemChangeWhiteInk,
      currentItemChangeWhitedataService,
      currentItemChangeSlit,
      currentItemChangeBackside,
      currentItemChangeOndemandFoil,
      currentItemChangeDeliveryForm,
      currentItemChangeSendCopy,
      currentItemChangeDeliveryDateProduct,
      currentItemChangeNum,
      currentItemChangeIndividualPackaging,
      currentItemAddToCart,
      currentItemRequestEstimatePdf,
      userActiveOrdersRequest,
      userPurchaseRequest,
      userPurchaseCancel,
      userPurchaseRequestInvoice,
      userPurchaseRequestReceipt,
      userPurchaseRequestHistories,
      userPurchaseChangeShipment,
      userAccountRequestUpdate,
      userAccountRequestLeave,
      userShipmentSetAddressFormDefaultValues,
      userShipmentInitAddressFormDefaultValues,
      userShipmentRequestDestinations,
      userShipmentAddDestination,
      userShipmentDeleteDestination,
      userShipmentUpdateDestination,
      userShipmentRequestSources,
      userShipmentAddSource,
      userShipmentDeleteSource,
      userShipmentUpdateSource,
      userUploadFormOpen,
      userUploadFormClose,
      userCancelFormOpen,
      userCancelFormClose,
      userChangeShipmentFormOpen,
      userChangeShipmentFormClose,
      userInvoiceFormOpen,
      userInvoiceFormClose,
      userReceiptFormOpen,
      userReceiptFormClose,
      userOrderRepeat,
      userEstimateToCart,
      userDownloadEstimatePdf,
      userLeave,
      checkoutChangePaymentMethod,
      checkoutChangeCoupon,
      checkoutChangePoint,
      checkoutChangeShipmentDestination,
      checkoutChangeShipmentSource,
      checkoutChangeShipmentDeliveryTime,
      checkoutChangeNote,
      consentChangeExampleUse,
      priorConsentChange,
      contactFormSubmit,
      contactFormSend,
      loadMaterials,
    },
  }
}
