import { http } from './init'

export const loginRequest = async (email: string, password: string) => {
  return await http({
    url: '/v2/login',
    method: 'post',
    data: {
      email: email,
      password: password,
    },
  })
    .then(res => {
      http.defaults.headers.common['Authorization'] = `Bearer ${res.data.data.token}`
      return res.data.data.token
    })
    .catch(err => {
      console.error(err)
      return ''
    })
}

export const loginCheckRequest = async (token: string) => {
  if (token === '') {
    return false
  }
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`

  return await http({
    method: 'get',
    url: '/v2/check-login',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      }
      return false
    })
    .catch(err => {
      console.error(err)
      return false
    })
}

export const validateResetTokenRequest = async (token: string) => {
  return await http({
    url: `/v2/reset/${token}`,
    method: 'get',
  })
    .then(res => {
      if (res.status === 200) {
        return true
      } else {
        return false
      }
    })
    .catch(err => {
      console.error(err)
    })
}

export const resetUrlRequest = async (email: string) => {
  return await http({
    url: '/v2/reset',
    method: 'post',
    data: {
      email: email,
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
    })
}

export const resetRequest = async (token: string, password: string) => {
  return await http({
    url: `/v2/reset/${token}`,
    method: 'post',
    data: {
      password: password,
    },
  })
    .then(res => {
      return res
    })
    .catch(err => {
      console.error(err)
    })
}
