import React from 'react'
import * as Sentry from '@sentry/browser'
import { HitlabelState, Action } from '@hitlabel.jp/main-site/types'
import {
  requestUserData,
  requestSources,
  requestDestinations,
  requestPurchase,
  requestActivePurchases,
  requestDeliveredPurchases,
  requestEstimates,
  requestPurchases,
  requestThanks,
} from '@hitlabel.jp/main-site/services'
import { ActionType } from '../actions'

export const effects = (state: HitlabelState, dispatch: React.Dispatch<Action>) => {
  /**
   * ユーザー情報を読み込んだときにSentryのidをセット
   */
  React.useEffect(() => {
    if (state.user.id && state.user.id > 0) {
      try {
        window.clarity('set', 'user_id', state.user.id)
        Sentry.setUser({ id: state.user.id.toString() })
        window.dataLayer.push({
          userId: state.user.id.toString(),
          customerId: state.user.id.toString(),
        })
      } catch (e) {
        // do nothing
      }
    }
  }, [state.user.id])
  /**
   * 自身のユーザー情報を取得
   **/
  React.useEffect(() => {
    if (!state.auth.token) {
      return
    }
    dispatch({ type: ActionType.USER_ACCOUNT_REQUEST })
    requestUserData()
      .then(res => {
        if (res) {
          dispatch({ type: ActionType.USER_ACCOUNT_RECEIVE, payload: res })
        }
      })
      .catch(err => {
        console.error(err)
      })
  }, [state.auth.token, state.location?.pathname === '/mypage', state.location?.pathname === '/mypage/account'])

  /**
   * /mypage, /checkout/shipment, /mypage/shipment にアクセスしたときに住所情報を読み込む
   */
  React.useEffect(() => {
    if (state.user.id === 0) {
      return
    }
    if (
      [
        '/mypage',
        '/mypage/',
        '/checkout/shipment',
        '/checkout/shipment/',
        '/mypage/shipment',
        '/mypage/shipment/',
      ].includes(state.location?.pathname as string)
    ) {
      dispatch({ type: ActionType.USER_SHIPMENT_REQUEST_DESTINATIONS })
      dispatch({ type: ActionType.USER_SHIPMENT_REQUEST_SOURCES })
      requestDestinations()
        .then(res => {
          if (res) {
            dispatch({ type: ActionType.USER_SHIPMENT_RECEIVE_DESTINATIONS, payload: res })
          }
        })
        .catch(err => {
          console.error(err)
        })

      requestSources()
        .then(res => {
          if (res) {
            dispatch({ type: ActionType.USER_SHIPMENT_RECEIVE_SOURCES, payload: res })
          }
        })
        .catch(err => {
          console.error(err)
        })
    }
  }, [state.user.id, state.location?.pathname])

  /**
   * /checkout/done/yyyyMMddNNN, /mypage/histories/yyyyMMddNNN にアクセスがあったときに完了ページを表示する
   * 初回（thnaked = 0のとき）だけ purchase イベントを発火させる
   */
  React.useEffect(() => {
    const matches = state.location?.pathname.match(/^\/(checkout|mypage)\/(done|histories)\/(\d{11})/)
    if (state.user.id !== 0 && matches) {
      const purchaseId = matches[3]
      requestPurchase(Number(purchaseId))
        .then(res => {
          dispatch({ type: ActionType.USER_CURRENT_PURCHASE_RECEIVE, payload: res })
          if (res.thanked === 0) {
            // 初回のみ
            requestThanks(Number(purchaseId)).then(ok => {
              if (ok) {
                dispatch({ type: ActionType.GA4_PURCHASE, payload: res })
              } else {
              }
            })
          }
        })
        .catch(() => {
          dispatch({ type: ActionType.USER_CURRENT_PURCHASE_RECEIVE, payload: null })
        })
    }
  }, [state.user.id, state.location?.pathname])

  /**
   * /mypage/ にアクセスがあったときにアクティブな注文を読み込む
   * 各種変更フォームを閉じたときにも読み込む
   */
  React.useEffect(() => {
    if (state.location?.pathname === '/mypage/' || state.location?.pathname === '/mypage') {
      dispatch({ type: ActionType.USER_ACTIVE_ORDERS_REQUEST })
      dispatch({ type: ActionType.USER_DELIVERED_ORDERS_REQUEST })
      dispatch({ type: ActionType.USER_ESTIMATES_REQUEST })
      requestActivePurchases()
        .then(res => {
          dispatch({ type: ActionType.USER_ACTIVE_ORDERS_RECEIVE, payload: res })
        })
        .catch(() => {
          dispatch({ type: ActionType.USER_ACTIVE_ORDERS_RECEIVE, payload: null })
        })
      requestDeliveredPurchases()
        .then(res => {
          dispatch({ type: ActionType.USER_DELIVERED_ORDERS_RECEIVE, payload: res })
        })
        .catch(() => {
          dispatch({ type: ActionType.USER_DELIVERED_ORDERS_RECEIVE, payload: null })
        })
      requestEstimates()
        .then(res => {
          dispatch({ type: ActionType.USER_ESTIMATES_RECEIVE, payload: res })
        })
        .catch(() => {
          dispatch({ type: ActionType.USER_ESTIMATES_RECEIVE, payload: null })
        })
    }
    if (state.location?.pathname === '/mypage/histories/' || state.location?.pathname === '/mypage/histories') {
      requestPurchases()
        .then(res => {
          dispatch({ type: ActionType.USER_PURCHASES_RECEIVE, payload: res })
        })
        .catch(() => {
          dispatch({ type: ActionType.USER_PURCHASES_RECEIVE, payload: null })
        })
    }
  }, [
    state.user.id,
    state.location?.pathname,
    state.user.uploadForm,
    state.user.cancelForm,
    state.user.changeShipmentForm,
    state.user.invoiceForm,
    state.user.receiptForm,
  ])
}
