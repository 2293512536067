import { http } from './init'
import { Product } from '@hitlabel.jp/main-site/types'

export const fetchProducts = async () => {
  return await http({
    method: 'get',
    url: '/v2/products',
  })
    .then(res => {
      const data = res.data.data
      const products = data.map((p: any) => {
        const setting = p.setting !== '' ? JSON.parse(p.setting) : ''
        const product: Product = {
          id: p.id,
          name: p.name,
          nameDetail: p.name_detail,
          productType: p.product_type,
          setting: setting,
        }
        return product
      })
      return products
    })
    .catch(err => {
      console.error(err)
    })
}
