import { HitlabelState, ContactForm } from '@hitlabel.jp/main-site/types'
import { initialContactForm } from '@hitlabel.jp/main-site/hooks/hitlabel/initial-state'
import toast from 'react-hot-toast'
import { navigate } from 'gatsby'

export const contactInit = (state: HitlabelState) => {
  const newState = { ...state }
  newState.contactForm = initialContactForm()
  return newState
}
export const contactSubmit = (payload: ContactForm, state: HitlabelState) => {
  const newState = { ...state }
  newState.contactForm = payload
  navigate('/contact/confirmation')
  return newState
}
export const contactSendRequest = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = true
  return newState
}
export const contactSendRequestSucceed = (state: HitlabelState) => {
  const newState = { ...state }
  newState.contactForm = initialContactForm()
  newState.requesting = false
  toast.success('お問い合わせの送信に成功しました')
  navigate('/contact/done')
  return newState
}
export const contactSendRequestFail = (state: HitlabelState) => {
  const newState = { ...state }
  newState.requesting = false
  toast.error('お問い合わせの送信に失敗しました')
  return newState
}
