import { http } from './init'
import axios from 'axios'
import { CartItem } from '@hitlabel.jp/main-site/types'
import camelcaseKeys from 'camelcase-keys'
import { saveAs } from 'file-saver'

export const requestEstimatePdf = async (to: string, items: CartItem[]) => {
  return http({
    url: '/v2/estimate-pdf',
    method: 'post',
    data: {
      to: to,
      items: items,
    },
  }).then(res => {
    if (res.status === 200) {
      return camelcaseKeys(res.data.data, { deep: true })
    }
  })
}

export const downloadAutoEstimatePdf = async (url: string, fileName: string) => {
  // ここだけbaseURLを使いたくないのでaxiosを直で使う
  axios({
    method: 'get',
    url: url,
    responseType: 'blob',
  }).then((res: any) => {
    const blob = new Blob([res.data], {
      type: res.data.type,
    })
    saveAs(blob, fileName)
  })
}
