//
export enum ActionType {
  TOGGLE_USER_MENU = 'TOGGLE_USER_MENU',
  TOGGLE_MENU = 'TOGGLE_MENU',
  USER_MENU_CLOSE = 'USER_MENU_CLOSE',

  LOAD_MATERIALS = 'LOAD_MATERIALS',
  PRODUCTS_RECEIVE = 'PRODUCTS_RECEIVE',

  // 認証
  AUTH_SIGNUP_CONFIRM = 'AUTH_SIGNUP_CONFIRM',
  AUTH_SIGNUP_REQUEST = 'AUTH_SIGNUP_REQUEST',
  AUTH_SIGNUP_RECEIVE = 'AUTH_SIGNUP_RECEIVE',
  AUTH_LOAD_TOKEN = 'AUTH_LOAD_TOKEN',
  AUTH_LOAD_TOKEN_FAIL = 'AUTH_LOAD_TOKEN_FAIL',
  AUTH_LOGIN_STARTED = 'AUTH_LOGIN_STARTED',
  AUTH_LOGIN_FINISHED = 'AUTH_LOGIN_FINISHED',
  AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST',
  AUTH_LOGIN_REQUEST_SUCCEED = 'AUTH_LOGIN_REQUEST_SUCCEED',
  AUTH_LOGIN_REQUEST_FAIL = 'AUTH_LOGIN_REQUEST_FAIL',
  AUTH_LOGIN_CHECK_SUCCEED = 'AUTH_LOGIN_CHECK_SUCCEED',
  AUTH_LOGIN_CHECK_FAIL = 'AUTH_LOGIN_CHECK_FAIL',
  AUTH_LOGOUT = 'AUTH_LOGOUT',
  AUTH_RESET_URL_REQUEST = 'AUTH_RESET_URL_REQUEST',
  AUTH_RESET_URL_REQUEST_SUCCEED = 'AUTH_RESET_URL_REQUEST_SUCCEED',
  AUTH_RESET_URL_REQUEST_FAIL = 'AUTH_RESET_URL_REQUEST_FAIL',
  AUTH_RESET_REQUEST = 'AUTH_RESET_REQUEST',
  AUTH_RESET_REQUEST_SUCCEED = 'AUTH_RESET_REQUEST_SUCCEED',
  AUTH_RESET_TOKEN_VALIDATE = 'AUTH_RESET_TOKEN_VALIDATE',
  AUTH_RESET_TOKEN_VALIDATE_REQUEST_SUCCEED = 'AUTH_RESET_TOKEN_VALIDATE_REQUEST_SUCCEED',
  AUTH_RESET_TOKEN_VALIDATE_REQUEST_FAIL = 'AUTH_RESET_TOKEN_VALIDATE_REQUEST_FAIL',

  // ユーザー

  // サンプル請求
  SAMPLE_FORM_SUBMIT = 'SAMPLE_FORM_SUBMIT',
  SAMPLE_FORM_REQUEST_SEND = 'SAMPLE_FORM_REQUEST_SEND',
  SAMPLE_FORM_REQUEST_SUCCEED = 'SAMPLE_FORM_REQUEST_SUCCEED',
  SAMPLE_FORM_REQUEST_FAIL = 'SAMPLE_FORM_REQUEST_FAIL',

  // 見積依頼
  ESTIMATE_FORM_SUBMIT = 'ESTIMATE_FORM_SUBMIT',
  ESTIMATE_FORM_REQUEST_SEND = 'ESTIMATE_FORM_REQUEST_SEND',
  ESTIMATE_FORM_REQUEST_SUCCEED = 'ESTIMATE_FORM_REQUEST_SUCCEED',
  ESTIMATE_FORM_REQUEST_FAIL = 'ESTIMATE_FORM_REQUEST_FAIL',

  ADDRESS_REQUEST = 'ADDRESS_REQUEST',
  ADDRESS_RECEIVE = 'ADDRESS_RECEIVE',
  LOCATION_CHANGED = 'LOCATION_CHANGED',
  PRICE_REQUEST = 'PRICE_REQUEST',
  PRICE_RECEIVE = 'PRICE_RECEIVE',

  REQUEST_NUMS = 'REQUEST_NUMS',

  // 仕様選択関連
  CURRENT_ITEM_INIT = 'CURRENT_ITEM_INIT',
  CURRENT_ITEM_RESET = 'CURRENT_ITEM_RESET',
  CURRENT_ITEM_RECEIVE_SIZE = 'CURRENT_ITEM_RECEIVE_SIZE',
  CURRENT_ITEM_RECEIVE_SHAPE = 'CURRENT_ITEM_RECEIVE_SHAPE',
  CURRENT_ITEM_RECEIVE_NUMS = 'CURRENT_ITEM_RECEIVE_NUMS',
  CURRENT_ITEM_RECEIVE_CALENDAR = 'CURRENT_ITEM_RECEIVE_CALENDAR',
  CURRENT_ITEM_REQUEST_PRICE = 'CURRENT_ITEM_REQUEST_PRICE',
  CURRENT_ITEM_RECEIVE_PRICE = 'CURRENT_ITEM_RECEIVE_PRICE',
  CURRENT_ITEM_CHANGE_Y = 'CURRENT_ITEM_CHANGE_Y',
  CURRENT_ITEM_CHANGE_X = 'CURRENT_ITEM_CHANGE_X',
  CURRENT_ITEM_CHANGE_SIZE = 'CURRENT_ITEM_CHANGE_SIZE',
  CURRENT_ITEM_CHANGE_NUM = 'CURRENT_ITEM_CHANGE_NUM',
  CURRENT_ITEM_CHANGE_INDIVIDUAL_PACKAGING = 'CURRENT_ITEM_CHANGE_INDIVIDUAL_PACKAGING',
  CURRENT_ITEM_CHANGE_ADHESIVE = 'CURRENT_ITEM_CHANGE_ADHESIVE',
  CURRENT_ITEM_CHANGE_LAMINATION = 'CURRENT_ITEM_CHANGE_LAMINATION',
  CURRENT_ITEM_CHANGE_HALFCUT = 'CURRENT_ITEM_CHANGE_HALFCUT',
  CURRENT_ITEM_CHANGE_FORMAT = 'CURRENT_ITEM_CHANGE_FORMAT',
  CURRENT_ITEM_CHANGE_CUTLINE_SERVICE = 'CURRENT_ITEM_CHANGE_CUTLINE_SERVICE',
  CURRENT_ITEM_CHANGE_CUTPATH = 'CURRENT_ITEM_CHANGE_CUTPATH',
  CURRENT_ITEM_CHANGE_WHITE_INK = 'CURRENT_ITEM_CHANGE_WHITE_INK',
  CURRENT_ITEM_CHANGE_WHITEDATA_SERVICE = 'CURRENT_ITEM_CHANGE_WHITEDATA_SERVICE',
  CURRENT_ITEM_CHANGE_SLIT = 'CURRENT_ITEM_CHANGE_SLIT',
  CURRENT_ITEM_CHANGE_BACKSIDE = 'CURRENT_ITEM_CHANGE_BACKSIDE',
  CURRENT_ITEM_CHANGE_ONDEMAND_FOIL = 'CURRENT_ITEM_CHANGE_ONDEMAND_FOIL',
  CURRENT_ITEM_CHANGE_DELIVERY_FORM = 'CURRENT_ITEM_CHANGE_DELIVERY_FORM',
  CURRENT_ITEM_CHANGE_SEND_COPY = 'CURRENT_ITEM_CHANGE_SEND_COPY',
  CURRENT_ITEM_CHANGE_DELIVERY_DATE_PRODUCT = 'CURRENT_ITEM_CHANGE_DELIVERY_DATE_PRODUCT',
  CURRENT_ITEM_CHANGE_NAME = 'CURRENT_ITEM_CHANGE_NAME',
  CURRENT_ITEM_REQUEST_ESTIMATE_PDF = 'CURRENT_ITEM_REQUEST_CHANGE_ESTIMATE_PDF',
  CURRENT_ITEM_RECEIVE_ESTIMATE_PDF = 'CURRENT_ITEM_RECEIVE_CHANGE_ESTIMATE_PDF',
  CURRENT_ITEM_ADD_TO_CART = 'CURRENT_ITEM_ADD_TO_CART',
  CURRENT_ITEM_SET_PROGRESS = 'CURRENT_ITEM_SET_PROGRESS',
  CURRENT_ITEM_SET_ERROR_SIZE = 'CURRENT_ITEM_SET_ERROR_SIZE',
  CURRENT_ITEM_SET_DELIVERY_DATE_OPTION = 'CURRENT_ITEM_SET_DELIVERY_DATE_OPTION',

  // カート関連
  CART_LOAD_FROM_LOCAL_STORAGE = 'CART_LOAD_FROM_LOCAL_STORAGE',
  CART_CLEAR = 'CART_CLEAR',
  CART_ITEM_DUPLICATE = 'CART_ITEM_DUPLICATE',
  CART_ITEM_DELETE = 'CART_ITEM_DELETE',
  CART_ITEM_CHANGE_NAME = 'CART_ITEM_CHANGE_NAME',
  CART_ITEM_CHANGE_SHIPPING_DATE = 'CART_ITEM_CHANGE_SHIPPING_DATE',
  CART_ITEM_CHANGE_NUM = 'CART_ITEM_CHANGE_NUM',
  CART_ITEM_CHANGE_X = 'CART_ITEM_CHANGE_X',
  CART_ITEM_CHANGE_Y = 'CART_ITEM_CHANGE_Y',
  CART_ITEM_SET_ERROR_SIZE = 'CART_ITEM_SET_ERROR_SIZE',
  CART_ITEM_CLEAR_ERROR_SIZE = 'CART_ITEM_CLEAR_ERROR_SIZE',
  CART_ITEM_RECEIVE_SIZE = 'CART_ITEM_RECEIVE_SIZE',
  CART_ITEM_RECEIVE_SHAPE = 'CART_ITEM_RECEIVE_SHAPE',
  CART_ITEM_CALC_PRICE = 'CART_ITEM_CALC_PRICE',
  CART_CALC_TOTAL_PRICE = 'CART_CALC_TOTAL_PRICE',
  CART_REQUEST_ESTIMATE_PDF = 'CART_REQUEST_ESTIMATE_PDF',
  CART_RECEIVE_ESTIMATE_PDF = 'CART_RECEIVE_ESTIMATE_PDF',

  // 自動見積PDF関連
  ESTIMATE_PDF_REQUEST = 'ESTIMATE_PDF_REQUEST',
  ESTIMATE_PDF_RECEIVE = 'ESTIMATE_PDF_RECEIVE',

  // チェックアウト
  CHECKOUT_START = 'CHECKOUT_START',
  CHECKOUT_CHANGE_COUPON = 'CHECKOUT_CHANGE_COUPON',
  CHECKOUT_VALIDATE_COUPON = 'CHECKOUT_VALIDATE_COUPON',
  CHECKOUT_VALIDATE_COUPON_DONE = 'CHECKOUT_VALIDATE_COUPON_DONE',
  CHECKOUT_VALIDATE_COUPON_FAIL = 'CHECKOUT_VALIDATE_COUPON_FAIL',
  CHECKOUT_CHANGE_POINT = 'CHECKOUT_CHANGE_POINT',
  CHECKOUT_CHANGE_PAYMENT_METHOD = 'CHECKOUT_CHANGE_PAYMENT_METHOD',
  CHECKOUT_CHANGE_SHIPMENT_SOURCE = 'CHECKOUT_CHANGE_SHIPMENT_SOURCE',
  CHECKOUT_CHANGE_SHIPMENT_DESTINATION = 'CHECKOUT_CHANGE_SHIPMENT_DESTINATION',
  CHECKOUT_CHANGE_SHIPMENT_DELIVERY_TIME = 'CHECKOUT_CHANGE_SHIPMENT_DELIVERY_TIME',
  CHECKOUT_REQUEST_SEND = 'CHECKOUT_REQUEST_SEND',
  CHECKOUT_REQUEST_SUCCEED = 'CHECKOUT_REQUEST_SUCCEED',
  CHECKOUT_REQUEST_FAIL = 'CHECKOUT_REQUEST_FAIL',
  CARD_TOKEN_REQUEST = 'CARD_TOKEN_REQUEST',
  CARD_TOKEN_RECEIVE = 'CARD_TOKEN_RECEIVE',
  CARD_TOKEN_ERROR = 'CARD_TOKEN_ERROR',

  CHECKOUT_CHANGE_NOTE = 'CHECKOUT_CHANGE_NOTE',
  CONSENT_CHANGE_EXAMPLE_USE = 'CONSENT_CHANGE_EXAMPLE_USE',
  PRIOR_CONSENT_CHANGE = 'PRIOR_CONSENT_CHANGE',

  // マイページ関連
  USER_ACCOUNT_REQUEST = 'USER_ACCOUNT_REQUEST',
  USER_ACCOUNT_RECEIVE = 'USER_ACCOUNT_RECEIVE',
  USER_ACCOUNT_REQUEST_UPDATE = 'USER_ACCOUNT_REQUEST_UPDATE',
  USER_ACCOUNT_DONE_UPDATE = 'USER_ACCOUNT_DONE_UPDATE',
  USER_ACCOUNT_FAIL_UPDATE = 'USER_ACCOUNT_FAIL_UPDATE',
  USER_ACCOUNT_REQUEST_LEAVE = 'USER_ACCOUNT_REQUEST_LEAVE',
  USER_ACCOUNT_DONE_LEAVE = 'USER_ACCOUNT_DONE_LEAVE',
  USER_ORDER_REQUEST_UPLOAD = 'USER_ORDER_REQUEST_UPLOAD',
  USER_ORDER_DONE_UPLOAD = 'USER_ORDER_DONE_UPLOAD',
  USER_ORDER_PROGRESS_UPLOAD = 'USER_ORDER_PROGRESS_UPLOAD',
  USER_ORDER_REPEAT = 'USER_ORDER_REPEAT',
  USER_ACTIVE_ORDERS_REQUEST = 'USER_ACTIVE_ORDERS_REQUEST',
  USER_ACTIVE_ORDERS_RECEIVE = 'USER_ACTIVE_ORDERS_RECEIVE',
  USER_DELIVERED_ORDERS_REQUEST = 'USER_DELIVERED_ORDERS_REQUEST',
  USER_DELIVERED_ORDERS_RECEIVE = 'USER_DELIVERED_ORDERS_RECEIVE',
  USER_ESTIMATES_REQUEST = 'USER_ESTIMATES_REQUEST',
  USER_ESTIMATES_RECEIVE = 'USER_ESTIMATES_RECEIVE',
  USER_PURCHASE_REQUEST = 'USER_PURCHASE_REQUEST',
  USER_PURCHASE_RECEIVE = 'USER_PURCHASE_RECEIVE',
  USER_PURCHASES_REQUEST = 'USER_PURCHASES_REQUEST',
  USER_PURCHASES_RECEIVE = 'USER_PURCHASES_RECEIVE',
  USER_PURCHASE_REQUEST_CANCEL = 'USER_PURCHASE_REQUEST_CANCEL',
  USER_PURCHASE_DONE_CANCEL = 'USER_PURCHASE_DONE_CANCEL',
  USER_PURCHASE_REQUEST_INVOICE = 'USER_PURCHASE_REQUEST_INVOICE',
  USER_PURCHASE_RECEIVE_INVOICE = 'USER_PURCHASE_RECEIVE_INVOICE',
  USER_PURCHASE_REQUEST_RECEIPT = 'USER_PURCHASE_REQUEST_RECEIPT',
  USER_PURCHASE_RECEIVE_RECEIPT = 'USER_PURCHASE_RECEIVE_RECEIPT',
  USER_PURCHASE_REQUEST_HISTORIES = 'USER_PURCHASE_REQUEST_HISTORIES',
  USER_PURCHASE_RECEIVE_HISTORIES = 'USER_PURCHASE_RECEIVE_HISTORIES',
  USER_PURCHASE_CHANGE_SHIPMENT_REQUEST = 'USER_PURCHASE_CHANGE_SHIPMENT_REQUEST',
  USER_PURCHASE_CHANGE_SHIPMENT_DONE = 'USER_PURCHASE_CHANGE_SHIPMENT_DONE',
  USER_SHIPMENT_INIT_ADDRESS_FORM_DEFAULT_VALUES = 'USER_SHIPMENT_INIT_ADDRESS_FORM_DEFAULT_VALUES',
  USER_SHIPMENT_SET_ADDRESS_FORM_DEFAULT_VALUES = 'USER_SHIPMENT_SET_ADDRESS_FORM_DEFAULT_VALUES',
  USER_SHIPMENT_REQUEST_DESTINATIONS = 'USER_SHIPMENT_REQUEST_DESTINATIONS',
  USER_SHIPMENT_RECEIVE_DESTINATIONS = 'USER_SHIPMENT_RECEIVE_DESTINATIONS',
  USER_SHIPMENT_ADD_DESTINATION = 'USER_SHIPMENT_ADD_DESTINATION',
  USER_SHIPMENT_ADD_DESTINATION_DONE = 'USER_SHIPMENT_ADD_DESTINATION_DONE',
  USER_SHIPMENT_DELETE_DESTINATION = 'USER_SHIPMENT_DELETE_DESTINATION',
  USER_SHIPMENT_DELETE_DESTINATION_DONE = 'USER_SHIPMENT_DELETE_DESTINATION_DONE',
  USER_SHIPMENT_UPDATE_DESTINATION = 'USER_SHIPMENT_UPDATE_DESTINATION',
  USER_SHIPMENT_UPDATE_DESTINATION_DONE = 'USER_SHIPMENT_UPDATE_DESTINATION_DONE',
  USER_SHIPMENT_REQUEST_SOURCES = 'USER_SHIPMENT_REQUEST_SOURCES',
  USER_SHIPMENT_RECEIVE_SOURCES = 'USER_SHIPMENT_RECEIVE_SOURCES',
  USER_SHIPMENT_ADD_SOURCE = 'USER_SHIPMENT_ADD_SOURCE',
  USER_SHIPMENT_ADD_SOURCE_DONE = 'USER_SHIPMENT_ADD_SOURCE_DONE',
  USER_SHIPMENT_DELETE_SOURCE = 'USER_SHIPMENT_DELETE_SOURCE',
  USER_SHIPMENT_DELETE_SOURCE_DONE = 'USER_SHIPMENT_DELETE_SOURCE_DONE',
  USER_SHIPMENT_UPDATE_SOURCE = 'USER_SHIPMENT_UPDATE_SOURCE',
  USER_SHIPMENT_UPDATE_SOURCE_DONE = 'USER_SHIPMENT_UPDATE_SOURCE_DONE',
  USER_CURRENT_PURCHASE_RECEIVE = 'USER_CURRENT_PURCHASE_RECEIVE',
  USER_UPLOAD_FORM_OPEN = 'USER_UPLOAD_FORM_OPEN',
  USER_UPLOAD_FORM_CLOSE = 'USER_UPLOAD_FORM_CLOSE',
  USER_CANCEL_FORM_OPEN = 'USER_CANCEL_FORM_OPEN',
  USER_CANCEL_FORM_CLOSE = 'USER_CANCEL_FORM_CLOSE',
  USER_CHANGE_SHIPMENT_FORM_OPEN = 'USER_CHANGE_SHIPMENT_FORM_OPEN',
  USER_CHANGE_SHIPMENT_FORM_CLOSE = 'USER_CHANGE_SHIPMENT_FORM_CLOSE',
  USER_INVOICE_FORM_OPEN = 'USER_INVOICE_FORM_OPEN',
  USER_INVOICE_FORM_CLOSE = 'USER_INVOICE_FORM_CLOSE',
  USER_RECEIPT_FORM_OPEN = 'USER_RECEIPT_FORM_OPEN',
  USER_RECEIPT_FORM_CLOSE = 'USER_RECEIPT_FORM_CLOSE',
  USER_ESTIMATE_TO_CART = 'USER_ESTIMATE_TO_CART',
  USER_LEAVE_DONE = 'USER_LEAVE_DONE',

  RECEIVE_HOLIDAYS = 'RECEIVE_HOLIDAYS',
  RECEIVE_SHIPPING_DATES = 'RECEIVE_SHIPPING_DATES',

  CONTACT_INIT = 'CONTACT_INIT',
  CONTACT_SUBMIT = 'CONTACT_SUBMIT',
  CONTACT_SEND_REQUEST = 'CONTACT_SEND_REQUEST',
  CONTACT_SEND_REQUEST_SUCCEED = 'CONTACT_SEND_REQUEST_SUCCEED',
  CONTACT_SEND_REQUEST_FAIL = 'CONTACT_SEND_REQUEST_FAIL',

  // 以下 Google アナリティクス 4 イベント https://developers.google.com/gtagjs/reference/ga4-events
  // に合わせた名称で設定。dataLayerでECイベントを送信するためのActionたち
  GA4_LOGIN = 'GA4_LOGIN',
  GA4_SIGN_UP = 'GA4_SIGN_UP',
  GA4_SEARCH = 'GA4_SEARCH',
  GA4_ADD_TO_CART = 'GA4_ADD_TO_CART',
  GA4_REMOVE_FROM_CART = 'GA4_REMOVE_FROM_CART',
  GA4_ADD_PAYMENT_INFO = 'GA4_ADD_PAYMENT_INFO',
  GA4_ADD_SHIPPING_INFO = 'GA4_ADD_PAYMENT_INFO',
  GA4_BEGIN_CHECKOUT = 'GA4_BEGIN_CHECKOUT',
  GA4_PURCHASE = 'GA4_PURCHASE',
  GA4_REFUND = 'GA4_REFUND',
  GA4_GENERATE_LEAD = 'GA4_GENERATE_LEAD',
  GA4_SELECT_ITEM = 'GA4_SELECT_ITEM',
  GA4_VIEW_CART = 'GA4_VIEW_CART',
  GA4_VIEW_ITEM = 'GA4_VIEW_ITEM',
  GA4_VIEW_ITEM_LIST = 'GA4_VIEW_ITEM_LIST',
  GA4_VIEW_PROMOTION = 'GA4_VIEW_PROMOTION',
}
