import { http } from './init'

import type { SampleForm } from '@hitlabel.jp/main-site/types'

export const sendSampleRequest = async (data: SampleForm) => {
  return await http({
    method: 'post',
    url: '/v2/sample',
    data: data,
  })
    .then((res) => {
      if (res.status === 200) {
        return true
      }
      return false
    })
    .catch((err) => {
      console.error(err)
      return false
    })
}
