import { http } from '../init'
import { saveAs } from 'file-saver'
import camelcaseKeys from 'camelcase-keys'

export const requestEstimates = async () => {
  return await http({
    url: `/v2/estimates?active=true`,
    method: 'get',
  })
    .then(res => {
      return camelcaseKeys(res.data.data, { deep: true })
    })
    .catch(err => {
      console.error(err)
    })
}

export const downloadEstimatePdf = async (estimateNumber: number, fileName: string) => {
  return await http({
    url: `/v2/estimates/${estimateNumber}/pdf`,
    method: 'get',
    responseType: 'blob',
  })
    .then(res => {
      const blob = new Blob([res.data], {
        type: res.data.type,
      })
      saveAs(blob, fileName)
      return true
    })
    .catch(err => {
      console.error(err)
      return false
    })
}
